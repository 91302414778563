<template>

   <sdPageHeader>
      <template #title>
      <a-row :gutter="30">
        <a-col>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 15px">
           Daily Leads Tagged Reports
          </sdHeading>
          <span style="margin: 0px; font-size: 16px; font-weight: 400">
          </span>
        </div>
        </a-col>
        </a-row>
        <ProductCard
    class="list-view"
    :style="{ width: '100%', marginBottom: '10px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;">
    
        <!--<BasicFormWrapper>-->
            <a-form
              name="contact"
              :rules="rules"
              :model="formState"
              @submit="handleOk"
              :layout="formState.layout"
            >
            <a-row :gutter="20">
            <a-col :md="5">
              <a-form-item name="center">
                <a-select
                  v-model:value="formState.center"
                  style="width: 100%"
                  showSearch
                >
                <a-select-option
                  v-for="item in branchData"
                  v-bind:key="item.branch_code"
                  :value="item.branch_code"
                >
                  {{ item.branch_code }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5">
              <a-form-item name="category">
                <a-select
                  v-model:value="formState.category"
                  style="width: 100%"
                  @change="onChange"
                  showSearch
                >
                <a-select-option value="SME">SME</a-select-option>
                <a-select-option value="Accounting">Accounting</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" v-if="dispInterest">
              <a-form-item name="interest">
                <a-select
                v-model:value="formState.interest"
                style="width: 100%"
                v-if="dispInterest"
                showSearch
                >
                <a-select-option value="Accounts">Accounts</a-select-option>
                <a-select-option value="Power BI">Power BI</a-select-option>
                <a-select-option value="Intent">Intent</a-select-option>
                <a-select-option value="Websights">Websights</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="condition">
             <a-date-picker
                  style="padding-top: 10px !important"
                  @change="DateSelect"
                  :defaultValue="selectrange"
                />   
            </a-form-item>
            </a-col>
            
            <a-col :md="5">
            <a-form-item>
              <a-button 
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
                class="btn-add_new"
                style="margin-top: -1.5px !important;"
              >
               <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="arrow-down-circle" size="14" />  &nbsp; 
              Get Report</span>
              </a-button>
             </a-form-item>
              </a-col>
             
              </a-row>
            </a-form>

          <!--</BasicFormWrapper>-->
          
          </div>
          </ProductCard>
      </template>
     
    </sdPageHeader>
     
  <Main>
    <sdCards headless>
    <sdHeading class="product-single-title" as="h3" style="margin: 10px">
    List of Leads
    </sdHeading>
      <template v-if="selectedoption == 'Active'">
      <a-row :gutter="15" style="padding: 10px">
            
            <a-col :xs="24">
              <TopToolBox>
                <div class="product-single-description">
                  <div
                    class="
                      product-single-title
                      page-header-actions
                      product-single-description
                    "
                    style="float: right"
                  >
                    <a-button
                      class="btn-signin"
                      size="small"
                      type="default"
                      v-if="checkselected"
                      @click="bulkIgnore('B')"
                    >
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="x-circle" size="14" /> &nbsp;
                        Dismiss</span
                      >
                    </a-button>
                    
                    <a-button class="btn-signin" size="small" @click="exportToCSV()">
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="download" size="14" /> &nbsp;
                        Export</span
                      >
                    </a-button>
                  </div>
                  <span style="margin: 0px; font-size: 16px; font-weight: 400">
                    <br />
                  </span>
                </div>
              </TopToolBox>
            </a-col>
          </a-row> 
        <a-row :gutter="15">
          <a-col :md="24">
             <!--<TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="TableData"
                :rowSelection="rowSelection"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 10,
                    total: TableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>-->
            <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :headers="table_headers"
                :data-source="TableData"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :rowSelection="rowSelection"
                :pagination="{
                  defaultPageSize: 10,
                  total: TableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      
    </sdCards>
  </Main>
</template> 
<script>
import { ContactPageheaderStyle, Action, UserTableStyleWrapper ,ProjectSorting,TopToolBox,} from '../../style';
import { defineComponent } from 'vue';
import { Main, CardToolbox, TableWrapper,BasicFormWrapper } from '../../../styled';
import { dispatch, useStore } from 'vuex';
import { ProductCard } from "./style";
import { defineAsyncComponent, computed, reactive, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { message, Notification } from 'ant-design-vue'; 
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default defineComponent({
  name: 'FollowupReport',
  components: {
    Main,
    CardToolbox,
    BasicFormWrapper,
    UserTableStyleWrapper,
    TableWrapper,ProjectSorting,TopToolBox,ProductCard,
  },
  setup() {
    var loading = ref(true);
    var branchData = ref();
    var userData = ref();
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref('Active');
    var selectrange = ref(null);
    var dispInterest = ref(false);
    const selectedChange = ref(null);
    const { dispatch, state } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT; 
    state.FollowData.data = [];
    onMounted(() => {
    getdata();
    }); 
    const formState = reactive({
      center: "Select Branch",
      category: "Select Team",
      interest: "Select Interest"
      });
    const rules = {
      center: [
        {
          required: true,
          message: "Please select criteria first",
          trigger: "blur",
        },
      ],
      };
    const onChange=(change)=> {
     selectedChange.value=change;
     if(selectedChange.value=="SME"){
     dispInterest.value=true;
     }
     else{
      dispInterest.value=false;
     }
    }  
    const DateSelect = (date, dateString) => {
      selectrange.value = dateString;
    };
    const handleOk = (values) => {
      dailyData();
    };
    const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows; 
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + ' Rows Selected';
        }
      },
     
    };
  const getdata = () => {
      loading.value = true;
      dispatch("getAssignFieldsData").then(
        (response) => {
          if (response.status == 200) {
            branchData.value = response.data[0];
            loading.value = false;
          } else {
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          console.log(error);
          loading.value = false;
        }
      );
      
    };
    const dailyData = () => {
      //var range = selectrange.value.toString().split(",");
     let startdate = new Date(selectrange.value.toString());
     let enddate = new Date(selectrange.value.toString());
      startdate = startdate.toLocaleDateString("en-CA");
      enddate = enddate.toLocaleDateString("en-CA");
        let api_data = [];
        api_data['center'] = formState.center;
        api_data['category'] = formState.category;
        api_data['interest'] = formState.interest;
        api_data['startdate'] = startdate;
        api_data['enddate'] = enddate;
        
        loading.value = true;
        dispatch("clicksTagReport",api_data).then(
        
            (response) => {
                if (response.status == 200) {
                    state.FollowData.data = response.data;
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Leads Loaded.",
                    });
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                state.FollowData.data = [];
                Notification["error"]({
                message: "Error!",
                description: "Error Loading Leads",
            });
          } 
          
        );
    }
    const TableColumns = [
      {
        title: "Lead Id",
        dataIndex: "lead_id",
        key: "lead_id",
        sorter: (a, b) => a.lead_id.length - b.lead_id.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Company",
        dataIndex: "Company",
        key: "Company",
        sorter: (a, b) => a.Company.length - b.Company.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Contact Person",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => a.Name.length - b.Name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "E-mail",
        dataIndex: "Email_Address",
        key: "Email_Address",
        sorter: (a, b) => a.Email_Address.length - b.Email_Address.length,
        sortDirections: ["descend", "ascend"],
      },
      //{
      //  title: "Branch",
      //  dataIndex: "branch_code",
      //  key: "branch_code",
      //  sorter: (a, b) => a.branch_code.length - b.branch_code.length,
      //  sortDirections: ["descend", "ascend"],
      //},
      //{
      //  title: "City",
      //  dataIndex: "City",
      //  key: "City",
      //  sorter: (a, b) => a.City.length - b.City.length,
      //  sortDirections: ["descend", "ascend"],
      //},
      {
        title: "Campaign Name",
        dataIndex: "campaign_name",
        key: "campaign_name",
        sorter: (a, b) => a.campaign_name.length - b.campaign_name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        sorter: (a, b) => a.category.length - b.category.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Clicked Date",
        dataIndex: "clicked_date",
        key: "clicked_date",
        sorter: (a, b) => a.clicked_date.length - b.clicked_date.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Current Status",
        dataIndex: "current_status",
        key: "current_status",
        sorter: (a, b) => a.current_status.length - b.current_status.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Remarks",
        dataIndex: "comment",
        key: "comment",
        sorter: (a, b) => a.comment.length - b.comment.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Task Status",
        dataIndex: "lead_status",
        key: "lead_status",
        sorter: (a, b) => a.lead_status.length - b.lead_status.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Lead Created DateTime",
        dataIndex: "created_at",
        key: "created_at",
        sorter: (a, b) => a.created_at.length - b.created_at.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "First Action DateTime",
        dataIndex: "first_action_date",
        key: "first_action_date",
        sorter: (a, b) => a.first_action_date.length - b.first_action_date.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "First Action Status",
        dataIndex: "first_action_status",
        key: "first_action_status",
        sorter: (a, b) => a.first_action_status.length - b.first_action_status.length,
        sortDirections: ["descend", "ascend"],
      },
      //{
      //  title: "Assigned User",
      //  dataIndex: "user_id",
      //  key: "user_id",
      //  sorter: (a, b) => a.user_id.length - b.user_id.length,
      //  sortDirections: ["descend", "ascend"],
      //},
      //{
      //  title: "Team Lead",
      //  dataIndex: "team_lead",
      //  key: "team_lead",
      //  sorter: (a, b) => a.team_lead.length - b.team_lead.length,
      //  sortDirections: ["descend", "ascend"],
      //},
      //{
      //  title: "Manager",
      //  dataIndex: "manager",
      //  key: "manager",
      //  sorter: (a, b) => a.manager.length - b.manager.length,
      //  sortDirections: ["descend", "ascend"],
      //},
      //{
      // title: "Total Transactions",
      //  dataIndex: "count",
      //  key: "count",
      //  sorter: (a, b) => a.count.length - b.count.length,
      //  sortDirections: ["descend", "ascend"],
      //},
      {
        title: "Last Action Date",
        dataIndex: "last_action_date",
        key: "last_action_date",
        sorter: (a, b) => a.last_action_date.length - b.last_action_date.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Last Action",
        dataIndex: "last_action_status",
        key: "last_action_status",
        sorter: (a, b) => a.last_action_status.length - b.last_action_status.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Schedule Date",
        dataIndex: "schedule_date",
        key: "schedule_date",
        sorter: (a, b) => a.schedule_date.length - b.schedule_date.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Schedule Time",
        dataIndex: "schedule_time",
        key: "schedule_time",
        sorter: (a, b) => a.schedule_time.length - b.schedule_time.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Conversion Details",
        dataIndex: "conversion_details",
        key: "conversion_details",
        sorter: (a, b) => a.conversion_details.length - b.conversion_details.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Contact Type",
        dataIndex: "contact_type",
        key: "contact_type",
        sorter: (a, b) => a.contact_type.length - b.contact_type.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "User",
        dataIndex: "main_user",
        key: "main_user",
        sorter: (a, b) => a.main_user.length - b.main_user.length,
        sortDirections: ["descend", "ascend"],
      },
     ];
     
   
     let TableData = computed(() =>
        state.FollowData.data.map((unreconcile) => {
          const {
            id,
            lead_id,
            Company,
            Name,
            assign_user,
            current_status,
            user_id,
            Lead_Source,
            branch_code,
            campaign_name,
            clicked_date,
            City,
            lead_status,
            comment,
            created_at,
            datetime,
            team_lead,
            manager,
            category,
            Email_Address,
            first_action_date,
            first_action_status,
            last_action_date,
            last_action_status,
            schedule_date,
            schedule_time,
            conversion_details,
            contact_type,
            main_user,
            } = unreconcile;
            var linedata = [];
        
          return {
            key: lead_id,
            id: id,
            lead_id: lead_id,
            Company: Company,
            Name:Name,
            assign_user: assign_user,
            current_status: current_status,
            user_id: user_id,
            branch_code: branch_code,
            campaign_name: campaign_name,
            clicked_date:clicked_date,
            City:City,
            lead_status:lead_status,
            comment: comment,
            created_at: created_at,
            datetime: datetime,
            team_lead: team_lead,
            manager: manager,
            category: category,
            Email_Address: Email_Address,
            first_action_date: first_action_date,
            first_action_status: first_action_status,
            last_action_date:last_action_date,
            last_action_status:last_action_status,
            schedule_date:schedule_date,
            schedule_time:schedule_time,
            conversion_details:conversion_details,
            contact_type:contact_type,
            main_user:main_user,
            } 
            
        }
        )
    );

    const exportToCSV = () => {
      const inputData = state.FollowData.data.map((unreconcile) => {
        const {
          id,
          Company,
          Name,
          count,
          assign_user,
          current_status,
          user_id,
          Lead_Source,
          branch_code,
          clicked_date,
          campaign_name,
          lead_id,
          children,
          City,
          lead_status,
          comment,
          created_at,
          datetime,
          team_lead,
          manager,
          category,
          Email_Address,
          first_action_date,
          first_action_status,
          last_action_date,
          last_action_status,
          schedule_date,
          schedule_time,
          conversion_details,
          contact_type,
          main_user,
        } = unreconcile;
        
        return {
            Id: lead_id,
            Company: Company,
            Name:Name,
            Email: Email_Address,
            "Campaign Name":campaign_name,
            Category: category,
            "Clicked Date":clicked_date,
            "Current Status": current_status,
            Remarks: comment,
            "Task Status": lead_status,
            "Lead Created DateTime": created_at,
            "First Action Date": first_action_date,
            "First Action": first_action_status,
            "Last Action Date":last_action_date,
            "Last Action":last_action_status,
            "Schedule Date":schedule_date,
            "Schedule Time":schedule_time,
            "Conversion Details":conversion_details,
            "Contact Type":contact_type,
            "User":main_user,
            };
      });

      var linedata = [];
      
      var range = selectrange.value.toString().split(",");
      const fileName ="Daily Leads Tagged Report " +range[0]+"-"+range[1];
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(inputData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };
    return {
      loading,
      TableColumns,
      TableData,
      branchData,
      userData,
      rowSelection,
      selectcount,
      checkselected,
      selectedoption,
      handleOk,
      exportToCSV,
      formState,
      selectrange,
      DateSelect,
      rules,
      onChange,
      selectedChange,
      dispInterest,
     };
  },
});
</script> 
<style scoped>
.ant-table-striped :deep(.table-striped) td th{
  background-color: #447316;
}
th {
  //background-color: #93BF34 !important;
}
.ant-btn-primary {
  background-color: #5C69B3 !important;
  border: 1px solid #5C69B3 !important;
}
.supp-head div{
        width: 250px;
}

.ant-col .ant-select {
  margin-top: 4px;
}
.ant-col button{
  margin-top: 5px;
}
table thead tr th:last-child {
    text-align: left !important;
}
.ant-page-header-heading {
  display: block !important;

}
</style>