<template>

   <sdPageHeader>
      <template #title>
      <a-row :gutter="30">
        <a-col>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 15px">
           Existing Data Update
          </sdHeading>
          <span style="margin: 0px; font-size: 16px; font-weight: 400">
          </span>
        </div>
        </a-col>
        </a-row>
        <ProductCard
    class="list-view"
    :style="{ width: '100%',}"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;">
    
        <!--<BasicFormWrapper>-->
            <a-form
              name="contact"
              :rules="rules"
              :model="formState"
              @submit="handleOk"
              layout="horizontal"
            >
            <a-row :gutter="30">
            <!--<a-col :md="4">
              <a-form-item name="center">
               <a-select
                  v-model:value="formState.center"
                  style="width: 100%"
                  showSearch
                >
                <a-select-option
                  v-for="item in branchData"
                  v-bind:key="item.branch_code"
                  :value="item.branch_code"
                >
                  {{ item.branch_code }}
                  </a-select-option>
                  
                </a-select> 
              </a-form-item>
            </a-col>-->
            <a-col :md="7">
            <a-form-item name="interest"  label="Select Fields">
                <a-select
                  v-model:value="formState.interest"
                  style="width: 100%"
                  mode="multiple"
                  maxTagCount=10
                  showSearch
                >
                <a-select-option value="Company">Company Name</a-select-option>
                <a-select-option value="Industry">Industry</a-select-option>
                <a-select-option value="interest">Interest</a-select-option>
                <a-select-option value="Lead_Source">Data Source</a-select-option>
                <a-select-option value="phone">Phone Number</a-select-option>
                <a-select-option value="Mobile">Mobile Number</a-select-option>
                <a-select-option value="board_nos">Board Number</a-select-option>
                <a-select-option value="management_level">Management Level</a-select-option>
                <a-select-option value="founded_year">Founded Year</a-select-option>
                <a-select-option value="linkedin">LinkedIn</a-select-option>
                <a-select-option value="facebook">Facebook</a-select-option>
                <a-select-option value="twitter">Twitter</a-select-option>
                <a-select-option value="email_unsubscribe">Email Unsubscribe</a-select-option>
                  
                </a-select>
              </a-form-item>
            </a-col>
            <!--<a-col :md="4">
              <a-form-item name="leadSource">
                <a-select
                  v-model:value="formState.leadSource"
                  style="width: 100%"
                  showSearch
                >
                  <a-select-option
                  v-for="item in sourceData"
                  v-bind:key="item.Source_type"
                  :value="item.Source_type"
                  >
                  {{ item.Source_type }}
                  </a-select-option>
                </a-select>
              </a-form-item>
             </a-col>-->
             <a-col :md="5">
             <a-form-item>
             <a-button size="default"
                type="primary"
                key="submit"
                class="btn-add_new"
                style="width: 90% !important; margin-top: -1.5px !important;margin-left: 20px !important;" @click="exportToCSV()">
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="download" size="14" /> &nbsp;
                        Download Template</span
                      >
            </a-button>
             </a-form-item>
             </a-col>
             <a-col :md="5">
              <a-form-item>
              <input ref="file" v-on:change="handleFileUpload()"  type="file" accept=".xlsx" />
              </a-form-item>
             </a-col>
            <a-col :md="4">
            <a-form-item>
              <a-button 
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
                class="btn-add_new"
                style="margin-top: -1.5px !important;margin-left: 20px !important;"
              >
               <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="arrow-up-circle" size="14" />  &nbsp; 
              Upload</span>
              </a-button>
             </a-form-item>
              </a-col>
              </a-row>
            </a-form>

          <!--</BasicFormWrapper>-->
          
          </div>
          </ProductCard>
      </template>
     
    </sdPageHeader>
     
  <Main>
    <sdCards headless>
    <sdHeading class="product-single-title" as="h3" style="margin: 10px">
    Leads List
    </sdHeading>
    <template v-if="selectedoption == 'Active'">
       <a-row :gutter="15">
          <a-col :md="24">
          <a-button
          size="default"
          type="primary"
          v-if="checkselected"
          @click="deleteBulk()"
          >
          <span style="display: inline-flex !important;
          align-items: center !important;">
          <sdFeatherIcons type="minus-circle" size="14" /> &nbsp;
          Delete Selected</span>
          </a-button>
          </a-col></a-row>
        <a-row :gutter="15">
          <a-col :md="24">
             <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="TableData"
                :rowSelection="rowSelection"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 10,
                    total: TableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      
    </sdCards>
  </Main>
</template> 
<script>
import { ContactPageheaderStyle, Action, UserTableStyleWrapper ,ProjectSorting,TopToolBox,} from '../../style';
import { defineComponent} from 'vue';
import { Main, CardToolbox, TableWrapper,BasicFormWrapper } from '../../../styled';
import { dispatch, useStore } from 'vuex';
import { ProductCard } from "./style";
import { defineAsyncComponent, computed, reactive, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { message, Notification } from 'ant-design-vue'; 
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default defineComponent({
  name: 'DataUpdateBulkForm',
  components: {
    Main,
    CardToolbox,
    BasicFormWrapper,
    UserTableStyleWrapper,
    TableWrapper,ProjectSorting,TopToolBox,ProductCard,
  },
  setup() {
    var loading = ref(true);
    var branchData = ref();
    var interestData = ref();
    var sourceData = ref();
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref('Active');
    const file = ref(null);
    const { dispatch, state } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT; 
    state.FreshData.data = [];
    onMounted(() => {
    getdata();
    }); 
    const formState = reactive({
      center: "Select Center",
      interest: [],
      leadSource: "Select Data Source",
    });
    const rules = {
      type: [
        {
          required: true,
          message: "Please select type first",
          trigger: "blur",
        },
      ],
      center: [
        {
          required: true,
          message: "Please select center first",
          trigger: "blur",
        },
      ],
      
      toUser: [
        {
          required: true,
          message: "Please select to User first!",
          trigger: "blur",
        },
      ],
      };
       const handleFileUpload = async() => {
           // debugger;
            console.log(file.value.files[0]);
           //state.FreshData.data= file.value.files[0];
            //Upload to server
        }
    const handleOk = (values) => {
      alertData();
    };
  const alertData = () => {
    let api_data = [];
    api_data['center'] = formState.center;
    api_data['interest'] = formState.interest;
    api_data['leadSource'] = formState.leadSource;
    api_data['selectedFile'] = file.value.files[0];
   dispatch("updateLeadFile",api_data).then(
            (response) => {
                if (response.status == 200) {
                 state.FreshData.data = response.data;
                    Notification["success"]({
                    message: "Succsess!",
                    description: "File Uploaded.",
                    });
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error: Entries in this are already existing in Database",
            });
          } 
          
        );
        }
    const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows; 
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + ' Rows Selected';
        }
      },
     
    };
const deleteBulk = () => {
        let api_data = [];
        api_data['selectedid'] = selectedid;
        
        loading.value = true;
        dispatch("deleteBulk",api_data).then(
            (response) => {
                if (response.status == 200) {
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Leads Deleted.",
                    });
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Deleting Leads",
            });
          } 
          
        );
    }
const getdata = () => {
      loading.value = true;
      dispatch("getBulkFieldsData").then(

        (response) => {
          if (response.status == 200) {
            branchData.value = response.data[0];
            interestData.value = response.data[2];
            sourceData.value = response.data[1];
            loading.value = false;
          } else {
            state.FreshData.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          console.log(error);
          loading.value = false;
          state.FreshData.data = [];
        }
      );
      
    };
  const TableColumns = [
    
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.id.length - b.id.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => a.Name.length - b.Name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Company",
        dataIndex: "Company",
        key: "Company",
        sorter: (a, b) => a.Company.length - b.Company.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Board nos",
        dataIndex: "board_nos",
        key: "board_nos",
        sorter: (a, b) => a.board_nos.length - b.board_nos.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        sorter: (a, b) => a.phone.length - b.phone.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Mobile",
        dataIndex: "Mobile",
        key: "Mobile",
        sorter: (a, b) => a.Mobile.length - b.Mobile.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Interest",
        dataIndex: "interest",
        key: "interest",
        sorter: (a, b) => a.interest.length - b.interest.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Turnover",
        dataIndex: "turn_over",
        key: "turn_over",
        sorter: (a, b) => a.turn_over.length - b.turn_over.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Probability",
        dataIndex: "Probability",
        key: "Probability",
        sorter: (a, b) => a.Probability.length - b.Probability.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Est_Close_Date",
        dataIndex: "Est_Close_Date",
        key: "Est_Close_Date",
        sorter: (a, b) => a.Est_Close_Date.length - b.Est_Close_Date.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Forecast",
        dataIndex: "Forecast",
        key: "Forecast",
        sorter: (a, b) => a.Forecast.length - b.Forecast.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Commission",
        dataIndex: "Commission",
        key: "Commission",
        sorter: (a, b) => a.Commission.length - b.Commission.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Job_Title",
        dataIndex: "Job_Title",
        key: "Job_Title",
        sorter: (a, b) => a.Job_Title.length - b.Job_Title.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Website",
        dataIndex: "Website",
        key: "Website",
        sorter: (a, b) => a.Website.length - b.Website.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Industry",
        dataIndex: "Industry",
        key: "Industry",
        sorter: (a, b) => a.Industry.length - b.Industry.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Email_Address",
        dataIndex: "Email_Address",
        key: "Email_Address",
        sorter: (a, b) => a.Email_Address.length - b.Email_Address.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Data_Source",
        dataIndex: "Lead_Source",
        key: "Lead_Source",
        sorter: (a, b) => a.Lead_Source.length - b.Lead_Source.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Address_1",
        dataIndex: "Address_1",
        key: "Address_1",
        sorter: (a, b) => a.Address_1.length - b.Address_1.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Address_2",
        dataIndex: "Address_2",
        key: "Address_2",
        sorter: (a, b) => a.Address_2.length - b.Address_2.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Address_3",
        dataIndex: "Address_3",
        key: "Address_3",
        sorter: (a, b) => a.Address_3.length - b.Address_3.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "City",
        dataIndex: "City",
        key: "City",
        sorter: (a, b) => a.City.length - b.City.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "County",
        dataIndex: "County",
        key: "County",
        sorter: (a, b) => a.County.length - b.County.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Post_Code",
        dataIndex: "Post_Code",
        key: "Post_Code",
        sorter: (a, b) => a.Post_Code.length - b.Post_Code.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Country",
        dataIndex: "Country",
        key: "Country",
        sorter: (a, b) => a.Country.length - b.Country.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "total_employees",
        dataIndex: "total_employees",
        key: "total_employees",
        sorter: (a, b) => a.total_employees.length - b.total_employees.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "deparment_name",
        dataIndex: "deparment_name",
        key: "deparment_name",
        sorter: (a, b) => a.deparment_name.length - b.deparment_name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "opportunity_amt",
        dataIndex: "opportunity_amt",
        key: "opportunity_amt",
        sorter: (a, b) => a.opportunity_amt.length - b.opportunity_amt.length,
        sortDirections: ["descend", "ascend"],
      },
      
    ];
  let TableData = computed(() =>
        state.FreshData.data.map((unreconcile) => {
          const {
            id,
            Name,
            Company,
            board_nos,
            phone,
            Mobile,
            interest,
            turn_over,
            Probability,
            Est_Close_Date,
            Forecast,
            Commission,
            Job_Title,
            Website,
            Industry,
            Email_Address,
            Lead_Source,
            Address_1,
            Address_2,
            Address_3,
            City,
            County,
            Post_Code,
            Country,
            total_employees,
            deparment_name,
            opportunity_amt,
            } = unreconcile;
          return {
            key: id,
            id: id,
            Name:Name,
            Company: Company,
            board_nos: board_nos,
            phone: phone,
            Mobile: Mobile,
            interest: interest,
            turn_over: turn_over,
            Probability: Probability,
            Est_Close_Date: Est_Close_Date,
            Forecast: Forecast,
            Commission: Commission,
            Job_Title: Job_Title,
            Website: Website,
            Industry: Industry,
            Email_Address: Email_Address,
            Lead_Source: Lead_Source,
            Address_1: Address_1,
            Address_2: Address_2,
            Address_3: Address_3,
            City: City,
            County: County,
            Post_Code: Post_Code,
            Country: Country,
            total_employees: total_employees,
            deparment_name: deparment_name,
            opportunity_amt: opportunity_amt,
            } 
            
        }
        )
    );

    const exportToCSV = () => {
      const selectedFields = formState.interest;
      //alert(formState.interest);
      const row = {};
      row.Email = "Enter Leads Email Address";
      if (selectedFields.includes("Company")) row.Company = "Your Company Name";
      if (selectedFields.includes("Industry")) row.Industry = "Your Industry Type";
      if (selectedFields.includes("interest")) row.interest = "Your Interest Type";
      if (selectedFields.includes("Lead_Source")) row.Lead_Source = "Data Source Type";
      if (selectedFields.includes("phone")) row.phone = "Your Phone Number";
      if (selectedFields.includes("Mobile")) row.Mobile = "Your Mobile Number";
      if (selectedFields.includes("board_nos")) row["board_nos"] = "Your Board Numbers";
      if (selectedFields.includes("management_level")) row["management_level"] = "Your Management Level Data";
      if (selectedFields.includes("founded_year")) row["founded_year"] = "Founded Year";
      if (selectedFields.includes("linkedin")) row.linkedin = "Linkedin Link";
      if (selectedFields.includes("facebook")) row.facebook = "Facebook Link";
      if (selectedFields.includes("twitter")) row.twitter = "Twitter Link";
      if (selectedFields.includes("email_unsubscribe")) row["email_unsubscribe"] = "true/false";

      // Create the Excel file with only selected fields as columns
      const fileName = "Update Leads Template";
      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet([row]);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };
    return {
      loading,
      TableColumns,
      TableData,
      branchData,
      interestData,
      sourceData,
      rowSelection,
      selectcount,
      checkselected,
      selectedoption,
      handleOk,
      file,
      deleteBulk,
      handleFileUpload,
      formState,
      rules,
      exportToCSV,
     };
  },
});
</script> 
<style scoped>
.ant-table-striped :deep(.table-striped) td th{
  background-color: #447316;
}
th {
  //background-color: #93BF34 !important;
}
.ant-btn-primary {
  background-color: #5C69B3 !important;
  border: 1px solid #5C69B3 !important;
}
.supp-head div{
        width: 250px;
}

.ant-col .ant-select {
  margin-top: 4px;
}
.ant-col button{
  margin-top: 5px;
}
table thead tr th:last-child {
    text-align: left !important;
}
.ant-page-header-heading {
  display: block !important;

}
</style>