<template>
   <sdPageHeader>
      <template #title>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 15px">
           Leads Detail
          </sdHeading>
          <span style="margin: 0px; font-size: 16px; font-weight: 400">
          </span>
        </div>
        <ProductCard
    class="list-view"
    :style="{ width: '100%', marginBottom: '20px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;">
        
        <a-row :gutter="30">
            <a-col :md='4'>
              <a-button
                size="default"
                type="primary"
                @click="showModal(route.params.id)"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="book" size="14" /> &nbsp;
              Campaign</span>
              </a-button>
            </a-col>
            <a-col :md='4'>
              <a-button
                size="default"
                type="primary"
                 @click="showCall(route.params.id)"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="edit-3" size="14" /> &nbsp;
              Call Logs</span>
              </a-button>
            </a-col>
            <a-col :md='4'>
              <a-button
                size="default"
                type="primary"
                @click="showHistory(route.params.id)"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="refresh-cw" size="14" /> &nbsp;
              History</span>
              </a-button>
            </a-col>
            <a-col :md='4'>
              <a-button
                size="default"
                type="primary"
                @click="showNote(route.params.id)"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="info" size="14" /> &nbsp;
              Info</span>
              </a-button>
            </a-col>
            <a-col :md='4'>
            <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="leadUpdate(route.params.id)"
            >
              <a-button
                size="default"
                type="primary"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="edit" size="14" /> &nbsp;
              Update</span>
              </a-button>
            </a-popconfirm>  
            </a-col>
            <a-col :md='4' v-if="disTarget">
            <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="leadToTarget(route.params.id)"
            >
              <a-button
                size="default"
                type="primary"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="crosshair" size="14" /> &nbsp;
              Target to Lead</span>
              </a-button>
            </a-popconfirm>  
            </a-col>
            <a-col :md='4' v-if="disQualify">
            <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="showModal7()"
            >
              <a-button
                size="default"
                type="primary"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="thumbs-up" size="14" /> &nbsp;
              Lead Qualification</span>
              </a-button>
            </a-popconfirm>  
            </a-col>
            <a-col :md='4'>
              <a-button
                size="default"
                type="primary"
                @click="showModal3('Follow Up')"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="watch" size="14" /> &nbsp;
              Follow-up</span>
              </a-button>
            </a-col>
            <a-col :md='4'>
              <a-button
                size="default"
                type="primary"
                @click="showModal4()"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="phone-call" size="14" /> &nbsp;
              Schedule Call</span>
              </a-button>
            </a-col>
            <!--<a-col :md='4'>
              <a-button
                size="default"
                type="primary"
                @click="showModal3('Conduct Meeting')"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="user" size="14" /> &nbsp;
              Conduct Meeting</span>
              </a-button>
            </a-col>-->
            <a-col :md='4' v-if="disPrposal">
            <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="showModal5()"
            >
              <a-button
                size="default"
                type="primary"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="thumbs-up" size="14" /> &nbsp;
              Proposal</span>
              </a-button>
            </a-popconfirm> 
            </a-col>
            <a-col :md='4' v-if="dispAccepted">
            <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="acceptedProposal(route.params.id)"
            >
              <a-button
                size="default"
                type="primary"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="thumbs-up" size="14" /> &nbsp;
              Proposal Accepted</span>
              </a-button>
            </a-popconfirm>  
            </a-col>
            <a-col :md='4' v-if="dispReview">
            <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="reviewProposal(route.params.id)"
            >
              <a-button
                size="default"
                type="primary"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="search" size="14" /> &nbsp;
              Review Proposal</span>
              </a-button>
            </a-popconfirm>  
            </a-col>
            <a-col :md='4' v-if="disrAccepted">
            <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="showModal6()"
            >
              <a-button
                size="default"
                type="primary"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="thumbs-up" size="14" /> &nbsp;
              Review Accepted</span>
              </a-button>
            </a-popconfirm>  
            </a-col>
            <a-col :md='4' v-if="disrRejected">
            <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="rejectedReview(route.params.id)"
            >
              <a-button
                size="default"
                type="primary"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="thumbs-down" size="14" /> &nbsp;
              Review Rejected</span>
              </a-button>
            </a-popconfirm>  
            </a-col>
            <a-col :md='4' v-if="dispRejected">
            <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="rejectedProposal(route.params.id)"
            >
              <a-button
                size="default"
                type="primary"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="thumbs-down" size="14" /> &nbsp;
              Proposal Rejected</span>
              </a-button>
            </a-popconfirm>  
            </a-col>
            <a-col :md='4' v-if="disDead">
            <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="showModal8()"
            >
              <a-button
                size="default"
                type="primary"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="eye-off" size="14" /> &nbsp;
              Not Interested</span>
              </a-button>
            </a-popconfirm>  
            </a-col>
            <a-col :md='4' v-if="disLost">
            <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="leadClosedLost(route.params.id)"
            >
              <a-button
                size="default"
                type="primary"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="x-circle" size="14" /> &nbsp;
              Closed Lost</span>
              </a-button>
            </a-popconfirm>  
            </a-col>
            <a-col :md='5' v-if="disAdmin">
            <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="leadDuplicate(route.params.id)"
            >
              <a-button
                size="default"
                type="primary"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="copy" size="14" /> &nbsp;
              Create Opportunity</span>
              </a-button>
            </a-popconfirm>  
            </a-col>
              </a-row>
            
          </div>
          
    <div class="product-list" style="padding: 10px;background-color:#ffffff;">
            <a-form
              name="contact"
              :model="formState"
              layout="vertical"
            >
            <a-row :gutter="30" style="" >
            <sdHeading class="product-single-title" as="h3" style="width: 100% !important; border-bottom: 1px solid #447316 !important;color: #447316 !important; margin: 15px" >
           Company Details:
          </sdHeading>
            </a-row>
            <a-row :gutter="30">
            <a-col :md="5">
              <a-form-item name="company" label="Company Name:">
                <a-input
                  v-model:value="formState.company"
                  placeholder="Input Company Name"
                />
              </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="industry" label="Industry Type:">
            <a-select
                v-model:value="formState.industry"
                style="width: 100%"
                showSearch
                >
                <a-select-option
                  v-for="item in industryData"
                  v-bind:key="item.vertical_type"
                  :value="item.vertical_type"
                >
                  {{ item.vertical_type }}
                  </a-select-option>
            </a-select>    
            </a-form-item>
            </a-col>
            <a-col :md="4">
              <a-form-item name="website" label="Website Address:">
                <a-input
                  v-model:value="formState.website"
                  placeholder="Input Website"
                />
              </a-form-item>
            </a-col>
            <a-col :md="4">
              <a-form-item name="turnover" label="Annual Turnover:">
                <a-input
                  v-model:value="formState.turnover"
                  placeholder="Input Annual Turnover"
                />
              </a-form-item>
            </a-col>
            <a-col :md="4">
              <a-form-item name="noemployees" label="No of Employees:">
                <a-input
                  v-model:value="formState.noemployees"
                  placeholder="Input No of Employees"
                />
              </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="30">
            <a-col :md="5">
              <a-form-item name="address1" label="Address 1:">
                <a-textarea
                  v-model:value="formState.address1"
                  placeholder="Input Address"
                />
              </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="address2" label="Address 1:">
            <a-textarea
                  v-model:value="formState.address2"
                  placeholder="Input Address"
                />   
            </a-form-item>
            </a-col>
            <a-col :md="4">
              <a-form-item name="city" label="City:">
                <a-input
                  v-model:value="formState.city"
                  placeholder="Input City"
                />
              </a-form-item>
            </a-col>
            <a-col :md="4">
              <a-form-item name="country" label="Country:">
                <a-select
                v-model:value="formState.country"
                style="width: 100%"
                showSearch
                >
                <a-select-option
                  v-for="item in countryData"
                  v-bind:key="item.country_name"
                  :value="item.country_name"
                >
                  {{ item.country_name }}
                  </a-select-option>
                </a-select>  
              </a-form-item>
            </a-col>
            <a-col :md="4">
              <a-form-item name="postal" label="Postal Code:">
                <a-input
                  v-model:value="formState.postal"
                  placeholder="Input Postal Code"
                />
              </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="30" style="" >
            <sdHeading class="product-single-title" as="h3" style="width: 100% !important; border-bottom: 1px solid #447316 !important;color: #447316 !important; margin: 15px" >
           Contact Person Details:
          </sdHeading>
            </a-row>
            <a-row :gutter="30">
            <a-col :md="5">
              <a-form-item name="name" label="Name:">
                <a-input
                  v-model:value="formState.name"
                  placeholder="Input Contact Person"
                />
              </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="jobtitle" label="Job Title:">
               <a-input
                  v-model:value="formState.jobtitle"
                  placeholder="Input Job Title"
                />
            </a-form-item>
            </a-col>
            <a-col :md="4">
              <a-form-item name="dept" label="Deptarment Name:">
                <a-input
                  v-model:value="formState.dept"
                  placeholder="Input Deptarment Name"
                />
              </a-form-item>
            </a-col>
            <a-col :md="4">
              <a-form-item name="interested" label="Interested In:">
                <a-select
                v-model:value="formState.interested"
                style="width: 100%"
                showSearch
                >
                <a-select-option
                  v-for="item in interestData"
                  v-bind:key="item.intrest_name"
                  :value="item.intrest_name"
                >
                  {{ item.intrest_name }}
                  </a-select-option>
            </a-select> 
              </a-form-item>
            </a-col>
            <a-col :md="5">
              <a-form-item name="board" label="Board Nos:">
                <a-textarea
                  v-model:value="formState.board"
                  placeholder="Input Board Nos"
                />
              </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="30">
            <a-col :md="5">
              <a-form-item name="mobile" label="Mobile:">
                <a-input
                  v-model:value="formState.mobile"
                  placeholder="Input Mobile"
                />
              </a-form-item>
            </a-col>
            <a-col :md="5">
              <a-form-item name="phone" label="phone:">
                <a-input
                  v-model:value="formState.phone"
                  placeholder="Input Phone"
                />
              </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="email" label="E-mail Address:">
            <a-input
                  v-model:value="formState.email"
                  placeholder="Input E-mail Address"
                />   
            </a-form-item>
            </a-col>
            <a-col :md="3">
              <a-form-item name="center" label="Center:">
                <a-select
                v-model:value="formState.center"
                style="width: 100%"
                showSearch
                >
                <a-select-option
                  v-for="item in branchData"
                  v-bind:key="item.branch_code"
                  :value="item.branch_code"
                >
                  {{ item.branch_code }}
                  </a-select-option>
            </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="linkedin" label="LinkedIn:">
            <a-input
                  v-model:value="formState.linkedin"
                  placeholder="Input LinkedIn"
                />   
            </a-form-item>
            </a-col>
            <a-col :md="3" v-if="dispDownload">
              <a-form-item name="Proposal Download" label="Download:">
                 <sdFeatherIcons type="download" size="14" /><a :href="formState.path" id="alink" download> Proposal Details
                </a>
              </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="management_level" label="Management_level:">
            <a-input
                  v-model:value="formState.management_level"
                  placeholder="Input Management Level"
                />   
            </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="founded_year" label="Founded Year:">
            <a-input
                  v-model:value="formState.founded_year"
                  placeholder="Input Founded Year"
                />   
            </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="facebook" label="Facebook:">
            <a-input
                  v-model:value="formState.facebook"
                  placeholder="Input Facebook"
                />   
            </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="twitter" label="Twitter:">
            <a-input
                  v-model:value="formState.twitter"
                  placeholder="Input Twitter"
                />   
            </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="30">
            </a-row>
            <a-row :gutter="30" style="" >
            <sdHeading class="product-single-title" as="h3" style="width: 100% !important; border-bottom: 1px solid #447316 !important;color: #447316 !important; margin: 15px" >
           Target/Leads Details:
          </sdHeading>
            </a-row>
            <a-row :gutter="30">
            <a-col :md="5">
              <a-form-item name="opportunity" label="Opportunity Amount:">
                <a-input
                  v-model:value="formState.opportunity"
                  placeholder="Input Opportunity Amount"
                />
              </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="leadsource" label="Data Source:">
            <a-select
                v-model:value="formState.leadsource"
                style="width: 100%"
                showSearch
                >
               <a-select-option
                  v-for="item in sourceData"
                  v-bind:key="item.Source_type"
                  :value="item.Source_type"
                >
                  {{ item.Source_type }}
                  </a-select-option>
            </a-select>    
            </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="leadtype" label="Lead Type:">
            <a-select
                v-model:value="formState.leadtype"
                style="width: 100%"
                showSearch
                >
               <a-select-option value="Email Campaign">
               Email Campaign
               </a-select-option>
               <a-select-option value="Cold Call">
               Cold Call
               </a-select-option>
               <a-select-option value="Inbound Call">
               Inbound Call
               </a-select-option>
               <a-select-option value="Inquiry Form">
               Inquiry Form
               </a-select-option>
               <a-select-option value="Referal">
               Referal
               </a-select-option>
               <a-select-option value="Cold Email">
               Cold Email
               </a-select-option>
               <a-select-option value="Facebook Ads">
               Facebook Ads
               </a-select-option>
               <a-select-option value="Google Ads">
               Google Ads
               </a-select-option>
               <a-select-option value="">
               Type Not Selected
               </a-select-option>
            </a-select>    
            </a-form-item>
            </a-col>
            <a-col :md="5">
              <a-form-item name="probability" label="Probability(%):">
                
                <a-select
                v-model:value="formState.probability"
                style="width: 100%"
                showSearch
                >
               <a-select-option value="Cold">
                  0-50% (Cold) 
               </a-select-option>
               <a-select-option value="Warm">
                  50-75% (Warm) 
               </a-select-option>
               <a-select-option value="Hold">
                  75-100% (Hot) 
               </a-select-option>
            </a-select> 
              </a-form-item>
            </a-col>
            <a-col :md="3">
              <a-form-item  label="Email Unsubscribe:">
                <a-checkbox @change="onChange" v-model:checked="formState.isCheck">
                </a-checkbox>
              </a-form-item>
            </a-col>
            <a-col :md="5">
              <a-form-item name="status" label="Current Status:">
                <a-input
                  v-model:value="formState.status"
                  placeholder="Input Current Status"
                />
              </a-form-item>
            </a-col>
            </a-row>
            
            <a-row :gutter="30" >
            <a-col :md="5" v-if="dispClosedWon">
              <a-form-item name="sla" label="SLA Signed Date:"  v-if="dispClosedWon">
                <a-date-picker
                  style="padding-top: 10px !important"
                  @change="DateSelect1"
                  :defaultValue="selectrange1"
                   v-if="dispClosedWon"
                />
              </a-form-item>
            </a-col>
            <a-col :md="5" v-if="dispClosedWon">
            <a-form-item name="live" label="Go Live Date:"  v-if="dispClosedWon">
            <a-date-picker
                  style="padding-top: 10px !important"
                  @change="DateSelect2"
                  :defaultValue="selectrange2"
                   v-if="dispClosedWon"
            />   
            </a-form-item>
            </a-col>
            
            </a-row>
            </a-form>
        </div>
          </ProductCard>
      </template>
    </sdPageHeader>
    <a-modal
        :type="localState.modalType"
        title="Campaign Information"
        :visible="localState.visible"
        :footer="null"
        :onCancel="handleCancel"
      >
        <div class="project-modal">
        <a-row :gutter="15">
          <a-col :md="24">
             <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="CampaignData"
                :headers="table_headers"
                :columns="CampaignColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 5,
                    total: CampaignData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
       </div>
      </a-modal>
      <a-modal
        :type="localState.modalType"
        title="Call Information"
        :visible="localState.callVisible"
        :footer="null"
        :onCancel="handleCancel"
        :width= "1080"
      >
        <div class="project-modal">
        <a-row :gutter="15">
          <a-col :md="24">
             <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="CallData"
                :headers="table_headers"
                :columns="CallColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 5,
                    total: CallData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
       </div>
      </a-modal>
      <a-modal
        :type="localState.modalType"
        title="Transaction Details"
        :visible="localState.historyVisible"
        :footer="null"
        :onCancel="handleCancel"
        :width= "920"
      >
        <div class="project-modal">
        <a-row :gutter="15">
          <a-col :md="24">
             <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="HistoryData"
                :headers="table_headers"
                :columns="HistoryColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 5,
                    total: HistoryData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
       </div>
      </a-modal>
      <a-modal
        :type="localState.modalType"
        title="More Information / Add Note"
        :visible="localState.noteVisible"
        :footer="null"
        :onCancel="handleCancel"
        :width= "720"
      >
        <div class="project-modal">
        <a-row :gutter="15">
          <a-col :md="24">
           <a-tabs>
            <a-tab-pane key="1" tab="Information">
            <a-form
              name="Information"
              :model="infoFormState"
              @submit="handleOk"
              layout="vertical"
            >
            <a-row :gutter="32">
            <a-col :md="16">
              <a-form-item name="info" label="Information:">
               <a-textarea
                  v-model:value="infoFormState.info"
                  placeholder="Input Inforamtion"
                /> 
              </a-form-item>
            </a-col>
            <a-col :md="8">
            <a-form-item name="users"  label="Assigned To:">
             <a-select
                v-model:value="infoFormState.assignedto"
                style="width: 100%"
                >
                <a-select-option
                  v-for="item in userData"
                  v-bind:key="item.user_id"
                  :value="item.user_id"
                >
                  {{ item.user_id }}
                  </a-select-option>
            </a-select>   
            </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item>
              <a-button 
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="plus-circle" size="14" />  &nbsp; 
              Add Info</span>
              </a-button>
              </a-form-item>
              </a-col>
             </a-row>
             <a-row :gutter="15">
          <a-col :md="24">
             <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="CommentData"
                :headers="table_headers"
                :columns="CommentColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 5,
                    total: CommentData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
            </a-form>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Comments" force-render>
            <a-form
              name="Information"
              :model="commentFormState"
              @submit="handleOk1"
              layout="vertical"
            >
            <a-row :gutter="32">
            <a-col :md="16">
              <a-form-item name="comment"  label="Comments:">
               <a-textarea
                  v-model:value="commentFormState.comment"
                  placeholder="Input Inforamtion"
                /> 
              </a-form-item>
            </a-col>
            <a-col :md="8">
            </a-col>
            <a-col :md="5">
            <a-form-item>
              <a-button 
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="plus-circle" size="14" />  &nbsp; 
              Add Comment</span>
              </a-button>
              </a-form-item>
              </a-col>
             </a-row>
            </a-form>
            </a-tab-pane>
          </a-tabs>  
          </a-col>
        </a-row>
       </div>
      </a-modal>
      <a-modal
        :type="localState.modalType"
        title="More Information / Add Note"
        :visible="localState.fallVisible"
        
        :footer="null"
        :onCancel="handleCancel"
        :width= "720"
      >
        <div class="project-modal">
        <a-row :gutter="15">
          <a-col :md="24">
            <a-form
              name="Call Logging"
              :model="callFormState"
              :rules="rules1"
              @submit="handleOk3"
              layout="vertical"
            >
            <a-row :gutter="32">
            <a-col :md="24">
              <a-form-item name="name" label="Cantact Person:">
               <a-input
                  v-model:value="callFormState.name"
                  placeholder="Input Inforamtion"
                  disabled
                /> 
              </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="32">
            <a-col :md="8">
            <a-form-item name="calltype"  label="Call Type:">
             <a-select
                v-model:value="callFormState.calltype"
                style="width: 100%"
                >
                <a-select-option
                  v-for="item in callType"
                  v-bind:key="item.call_type"
                  :value="item.call_type"
                >
                  {{ item.call_type }}
                  </a-select-option>
            </a-select>   
            </a-form-item>
            </a-col>
            <a-col :md="8">
            <a-form-item name="Date"  label="Date:">
             <a-date-picker
                  style="padding-top: 5px !important"
                  @change="DateSelect"
                  :defaultValue="selectrange"
              />   
            </a-form-item>
            </a-col>
            <a-col :md="3">
            <a-form-item name="hour"  label="Hours:">
             <a-input
              type="number"
              v-model:value="callFormState.hour"
              max="24"
              min="1"
              /> 
            </a-form-item>
            </a-col>
            <a-col :md="3">
            <a-form-item name="min"  label="Min:">
             <a-input
              type="number"
              v-model:value="callFormState.min"
              max="60"
              min="0"
              />
            </a-form-item>
            </a-col>
            <a-col :md="8">
            <a-form-item name="assigned"  label="Assigned To:">
             <a-select
                v-model:value="callFormState.assigned"
                style="width: 100%"
                >
                <a-select-option
                  v-for="item in userData"
                  v-bind:key="item.user_id"
                  :value="item.user_id"
                >
                  {{ item.user_id }}
                  </a-select-option>
            </a-select>   
            </a-form-item>
            </a-col>
            <a-col :md="8">
            <a-form-item name="contact_type"  label="Contact Type:">
             <a-select
                v-model:value="callFormState.contact_type"
                style="width: 100%"
                showSearch
                >
                <a-select-option value="Voicemail">Voicemail</a-select-option>
                <a-select-option value="GK">GK</a-select-option>
                <a-select-option value="DM">DM</a-select-option>
                <a-select-option value="Not Connected">Not Connected</a-select-option>
                <a-select-option value="Ringing">Ringing</a-select-option>
                <a-select-option value="Call Back">Call Back</a-select-option>
                <a-select-option value="Email Followup Only">Email Followup Only</a-select-option>
                </a-select>  
            </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="32">
            <a-col :md="24">
              <a-form-item name="note" label="Note:">
               <a-textarea
                  v-model:value="callFormState.note"
                  placeholder="Input Inforamtion"
                /> 
              </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item>
              <a-button 
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="save" size="14" />  &nbsp; 
             Save</span>
              </a-button>
              </a-form-item>
              </a-col>
             </a-row>
            </a-form>
           </a-col>
        </a-row>
       </div>
      </a-modal>
      <a-modal
        :type="localState.modalType"
        title="More Information / Add Note"
        :visible="localState.schedVisible"
        :footer="null"
        :onCancel="handleCancel"
        :width= "720"
      >
        <div class="project-modal">
        <a-row :gutter="15">
          <a-col :md="24">
            <a-form
              name="Call Logging"
              :model="schedFormState"
              :rules="rules"
              @submit="handleOk4"
              layout="vertical"
            >
            <a-row :gutter="32">
            <a-col :md="24">
              <a-form-item name="name" label="Cantact Person:">
               <a-input
                  v-model:value="schedFormState.name"
                  placeholder="Input Inforamtion"
                  disabled
                /> 
              </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="32">
            <a-col :md="8">
            <a-form-item name="calltype"  label="Call Type:">
             <a-select
                v-model:value="schedFormState.calltype"
                style="width: 100%"
                >
                <a-select-option
                  v-for="item in callType"
                  v-bind:key="item.call_type"
                  :value="item.call_type"
                >
                  {{ item.call_type }}
                  </a-select-option>
            </a-select>   
            </a-form-item>
            </a-col>
            <a-col :md="8">
            <a-form-item name="Date"  label="Date:">
             <a-date-picker
                  style="padding-top: 5px !important"
                  @change="DateSelect"
                  :defaultValue="selectrange"
              />   
            </a-form-item>
            </a-col>
            <a-col :md="3">
            <a-form-item name="hour"  label="Hours:">
             <a-input
              type="number"
              v-model:value="schedFormState.hour"
              max="24"
              min="1"
              /> 
            </a-form-item>
            </a-col>
            <a-col :md="3">
            <a-form-item name="min"  label="Min:">
             <a-input
              type="number"
              v-model:value="schedFormState.min"
              max="60"
              min="0"
              />
            </a-form-item>
            </a-col>
            <a-col :md="8">
            <a-form-item name="assigned"  label="Assigned To:">
             <a-select
                v-model:value="schedFormState.assigned"
                style="width: 100%"
                >
                <a-select-option
                  v-for="item in userData"
                  v-bind:key="item.user_id"
                  :value="item.user_id"
                >
                  {{ item.user_id }}
                  </a-select-option>
            </a-select>   
            </a-form-item>
            </a-col>
            <a-col :md="8">
            <a-form-item name="type"  label="Type:">
             <a-select
                v-model:value="schedFormState.type"
                style="width: 100%"
                >
                <a-select-option value="Schedule Zoom Call">Schedule Zoom Call
                </a-select-option>
                <a-select-option value="In-Person Meeting">In-Person Meeting
                </a-select-option>
            </a-select>   
            </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="32">
            <a-col :md="24">
              <a-form-item name="note" label="Note:">
               <a-textarea
                  v-model:value="schedFormState.note"
                  placeholder="Input Inforamtion"
                /> 
              </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item>
              <a-button 
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="save" size="14" />  &nbsp; 
             Save</span>
              </a-button>
              </a-form-item>
              </a-col>
             </a-row>
            </a-form>
           </a-col>
        </a-row>
       </div>
      </a-modal>
      <a-modal
        :type="localState.modalType"
        title="Upload Proposal"
        :visible="localState.proposalVisible"
        :footer="null"
        :onCancel="handleCancel"
        :width= "720"
      >
        <div class="project-modal">
        <a-row :gutter="15">
          <a-col :md="24">
            <a-form
              name="Proposal Upload"
              :model="proposalFormState"
              @submit="handleOk5"
              layout="vertical"
            >
            <a-row :gutter="32">
            <a-col :md="24">
              <a-form-item name="name" label="Upload File:">
               <input ref="file" type="file" accept=".pdf" />
              </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="32">
            <a-col :md="5">
            <a-form-item>
              <a-button 
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="upload" size="14" />  &nbsp; 
             Upload</span>
              </a-button>
              </a-form-item>
              </a-col>
             </a-row>
            </a-form>
           </a-col>
        </a-row>
       </div>
      </a-modal>
      <a-modal
        :type="localState.modalType"
        title="Upload Review Proposal"
        :visible="localState.reviewVisible"
        :footer="null"
        :onCancel="handleCancel"
        :width= "720"
      >
        <div class="project-modal">
        <a-row :gutter="15">
          <a-col :md="24">
            <a-form
              name="Proposal Upload"
              :model="reviewFormState"
              @submit="handleOk6"
              layout="vertical"
            >
            <a-row :gutter="32">
            <a-col :md="24">
              <a-form-item name="name" label="Upload Revised File:">
               <input ref="file1" type="file" accept=".pdf" />
              </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="32">
            <a-col :md="5">
            <a-form-item>
              <a-button 
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="upload" size="14" />  &nbsp; 
             Upload</span>
              </a-button>
              </a-form-item>
              </a-col>
             </a-row>
            </a-form>
           </a-col>
        </a-row>
       </div>
      </a-modal>
      <a-modal
        :type="localState.modalType"
        title="Lead Qualification Form"
        :visible="localState.qualyVisible"
        :footer="null"
        :onCancel="handleCancel"
        :width= "720"
      >
        <div class="project-modal">
        <a-row :gutter="15">
          <a-col :md="24">
            <a-form
              name="Lead Qualification"
              :model="reviewFormState"
              @submit="handleOk7"
              layout="vertical"
            >
            <a-row :gutter="32">
            <a-col :md="24">
              <a-form-item name="name" label="Select Manager to Assign:">
              <a-select
                v-model:value="qualyFormState.assign"
                style="width: 100%"
                >
                <a-select-option
                  v-for="item in managerData"
                  v-bind:key="item.user_id"
                  :value="item.user_id"
                >
                  {{ item.user_id }}
                  </a-select-option>
              </a-select> 
              </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="32">
            <a-col :md="5">
            <a-form-item>
              <a-button 
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="upload" size="14" />  &nbsp; 
             Select</span>
              </a-button>
              </a-form-item>
              </a-col>
             </a-row>
            </a-form>
           </a-col>
        </a-row>
       </div>
      </a-modal>
      <a-modal
        :type="localState.modalType"
        title="Lead Dead Form"
        :visible="localState.deadVisible"
        :footer="null"
        :onCancel="handleCancel"
        :width= "720"
      >
        <div class="project-modal">
        <a-row :gutter="15">
          <a-col :md="24">
            <a-form
              name="Lead Dead"
              :model="reviewFormState"
              @submit="handleOk8"
              layout="vertical"
              :rules="rules2"
            >
            <a-row :gutter="32">
            <a-col :md="24">
              <a-form-item name="name" label="Comments:">
              <a-select
                v-model:value="deadFormState.comment"
                style="width: 100%"
                >
                <a-select-option value="DND">DND</a-select-option>
                <a-select-option value="Company Closed">Company Closed</a-select-option>
                <a-select-option value="In-House">In-House</a-select-option>
                <a-select-option value="Retired">Retired</a-select-option>
                <a-select-option value="Not Interested">Not Interested</a-select-option>
                <a-select-option value="May Outsource in Future">May Outsource in Future</a-select-option>
                <a-select-option value="Prefer to keep inhouse">Prefer to keep inhouse</a-select-option>
                <a-select-option value="Small Firm">Small Firm</a-select-option>
                <a-select-option value="Bad Experience with Outsourcing">Bad Experience with Outsourcing</a-select-option>
                <a-select-option value="Already Outsourcing">Already Outsourcing</a-select-option>
                <a-select-option value="No Requirement">No Requirement</a-select-option>
                <a-select-option value="Incorrect Details">Incorrect Details</a-select-option>
                <a-select-option value="Invalid Leads">Invalid Leads</a-select-option>
                <a-select-option value="DM not interested">DM not interested</a-select-option>
                <a-select-option value="Others">Others</a-select-option>            
              </a-select> 
              </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="32">
            <a-col :md="24">
              <a-form-item name="name">
              <a-textarea
                  v-model:value="deadFormState.notes"
                  placeholder="Input Comments"
                  required
                 />
              </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="32">
            <a-col :md="5">
            <a-form-item>
              <a-button 
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="upload" size="14" />  &nbsp; 
             Select</span>
              </a-button>
              </a-form-item>
              </a-col>
             </a-row>
            </a-form>
           </a-col>
        </a-row>
       </div>
      </a-modal>
</template> 
<script>
import { ContactPageheaderStyle, Action, UserTableStyleWrapper ,ProjectSorting,TopToolBox,} from '../../style';
import { defineComponent } from 'vue';
import { Main, CardToolbox, TableWrapper } from '../../../styled';
import { dispatch, useStore } from 'vuex';
import { ProductCard } from "./style";
import { defineAsyncComponent, computed, reactive, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { message, Notification } from 'ant-design-vue'; 
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default defineComponent({
  name: 'CentersForm',
  components: {
    Main,
    CardToolbox,
    UserTableStyleWrapper,
    TableWrapper,ProjectSorting,TopToolBox,ProductCard,
  },
  setup() {
    var loading = ref(true);
    var checkselected = ref(false);
    var disDead = ref(true);
    var disTarget = ref(false);
    var disQualify = ref(false);
    var disLost = ref(false);
    var disPrposal = ref(false);
    var dispAccepted = ref(false);
    var dispReview = ref(false);
    var dispRejected = ref(false);
    var disrAccepted = ref(false);
    var disrRejected = ref(false);
    var dispDownload = ref(false);
    var dispClosedWon = ref(false);
    var disAdmin = ref(false);
    var isChecked = ref(false);
    const router = useRouter();
    var selectcount = ref(null);
    var selectedid = ref(null);
    var linedata = [];
    var path = ref();
    const file = ref(null);
    const file1 = ref(null);
    var industryData = ref();
    var countryData = ref();
    var interestData = ref();
    var managerData = ref();
    var branchData = ref();
    var sourceData = ref();
    var userData = ref();
    var callType = ref();
    var callTypes = ref();
    var selectrange = ref(null);
    const checked = ref(null);
    var selectedoption = ref('Active');
    const { dispatch, state } = useStore();
    var supplierdrop = ref();
    var ids = ref();
    var contact_id = ref(null);
    const route = useRoute();
    var selectrange1 = ref(null);
    var selectrange2 = ref(null);
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT; 
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));
    onMounted(() => {
    loadData();
    disVal();
    getdata(route.params.id);
    });
    const disVal = () => {
   
     let role = JSON.parse(localStorage.getItem("usr_role"));
      
      //alert(role.role_id);
      if (role.role_id=="1" ) {
            disAdmin.value=true;
          } 
      else{
            disAdmin.value=false;
          }
    };
    const DateSelect1 = (date, dateString) => {
      selectrange1.value = dateString;
      //alert(selectrange1.value);
      
    };
    const DateSelect2 = (date, dateString) => {
      selectrange2.value = dateString;
      //alert(selectrange2.value);
    };
    const rules = {
      calltype: [
        {
          required: true,
          message: "Please select Call-Type",
          trigger: "blur",
        },
      ],
      date: [
        {
          required: true,
          message: "Please select Date",
          trigger: "blur",
        },
      ],
      assigned: [
        {
          required: true,
          message: "Please select Assigned User",
          trigger: "blur",
        },
      ],
      note: [
        {
          required: true,
          message: "Please Add Note",
          trigger: "blur",
        },
      ],
      type: [
        {
          required: true,
          message: "Please select Schedule Type",
          trigger: "blur",
        },
      ],
      };
      const rules1 = {
      calltype: [
        {
          required: true,
          message: "Please select Call-Type",
          trigger: "blur",
        },
      ],
      date: [
        {
          required: true,
          message: "Please select Date",
          trigger: "blur",
        },
      ],
      assigned: [
        {
          required: true,
          message: "Please select Assigned User",
          trigger: "blur",
        },
      ],
      note: [
        {
          required: true,
          message: "Please Add Note",
          trigger: "blur",
        },
      ],
     
      };
    const rules2 = {
      comment: [
        {
          required: true,
          message: "Please select Comments",
          trigger: "blur",
        },
      ],
      notes: [
        {
          required: true,
          message: "Please Insert Notes",
          trigger: "blur",
        },
      ],
     
      };
      

    const formState = reactive({
    company:"",
    industry:"",
    website:"",
    turnover:"",
    noemployees:"",
    address1:"",
    address2:"",
    city:"",
    country:"",
    postal:"",
    name:"",
    jobtitle:"",
    dept:"",
    interested:"",
    board:"",
    mobile:"",
    phone:"",
    email:"",
    path:"",
    center:"",
    linkedin:"",
    opportunity:"",
    leadsource:"",
    leadtype:"",
    probability:"",
    isCheck: false,
    status:"",
    management_level:"",
    founded_year:"",
    facebook:"",
    twitter:"",  
    });
    const callFormState = reactive({
    name:"",
    calltype:"",
    contact_type:"",
    date:"",
    hour:"",
    min:"",
    assigned:"",
    note:"",
    });
    const proposalFormState = reactive({
    
    });
    const reviewFormState = reactive({
    
    });
    const schedFormState = reactive({
    name:"",
    calltype:"",
    date:"",
    hour:"",
    min:"",
    assigned:"",
    type:"",
    note:"",
    });
    const infoFormState = reactive({
    assignedto:"",
    info:"",
    });
    const commentFormState = reactive({
    comment:"",
    });
    const qualyFormState = reactive({
    assign:" Select manager name to assign lead",
    });
    const deadFormState = reactive({
    comment:"Select Reason to Dead Lead",
    notes: ""
    });
    state.LeadsIndex.data = [];
    const handleOk = (values) => {
    addInformation(route.params.id);  
    };
    const handleOk1 = (values) => {
    addComment(route.params.id);
    };
    const handleOk3 = (values) => {
    addCallDetails(route.params.id,route.params.click_id);
    };
    const handleOk4 = (values) => {
    schedCallDetails(route.params.id);
    };
    const handleOk5 = (values) => {
    leadProposal(route.params.id);
    };
    const handleOk6 = (values) => {
    reviewAccepted(route.params.id);
    };
    const handleOk7 = (values) => {
    //alert(qualyFormState.assign)
    leadQualification(route.params.id);
    };
    const handleOk8 = (values) => {
    //alert(qualyFormState.assign)
    leadDead(route.params.id,route.params.click_id);
    };
const onChange = (check) => {
      
    //alert(formState.isCheck);
      
    };

const leadsIndexLoad = (data) => {
    callFormState.name=data[0].Name;
    schedFormState.name=data[0].Name;
    formState.company=data[0].Company;
    formState.industry=data[0].Industry;
    formState.website=data[0].Website;
    formState.turnover=data[0].turn_over;
    formState.noemployees=data[0].total_employees;
    formState.address1=data[0].Address_1;
    formState.address2=data[0].Address_2;
    formState.city=data[0].City;
    formState.country=data[0].Country;
    formState.postal=data[0].Post_Code;
    formState.name=data[0].Name;
    formState.jobtitle=data[0].Job_Title;
    formState.dept=data[0].deparment_name;
    formState.interested=data[0].interest;
    formState.board=data[0].board_nos;
    formState.mobile=data[0].Mobile;
    formState.phone=data[0].phone;
    formState.email=data[0].Email_Address;
    selectrange1.value=data[0].sla;
    selectrange2.value=data[0].live;
    path=data[0].path;
    if(data[0].email_unsubscribe==null  || data[0].email_unsubscribe=="No" || data[0].email_unsubscribe=="false"){
    formState.isCheck=false;
    }
    else {
    formState.isCheck=true;

    }
    //isChecked=data[0].email_unsubscribe;
    formState.path=data[0].path;
    formState.center=data[0].branch_code;
    formState.linkedin=data[0].linkedin;
    formState.opportunity=data[0].opportunity_amt;
    formState.leadsource=data[0].Lead_Source;
    formState.leadtype=data[0].lead_type;
    formState.probability=data[0].Probability;
    formState.status=data[0].current_status;
    formState.management_level=data[0].management_level;
    formState.founded_year=data[0].founded_year;
    formState.facebook=data[0].facebook;
    formState.twitter=data[0].twitter;
    btnDisplay(data[0].current_status);
    //alert(isChecked);
    };
    const onCancel = () => {
      localState.visible = false;
      localState.callVisible = false;
      localState.historyVisible = false;
      localState.noteVisible= false;
      localState.fallVisible= false;
      localState.schedVisible= false;
      localState.proposalVisible= false;
      localState.reviewVisible= false;
      localState.qualyVisible= false;
      localState.deadVisible= false;
    };
    
    const btnDisplay = (status) => {
    //alert(status);
    if(status=="Target In-Progress"||status=="Opportunity In-Progress"||status=="Mail Sent"){
     disTarget.value=true;
    }
    else if (status=="Lead In-Progress") {
      disQualify.value=true;
    }
    else if (status=="Prospecting") {
      disDead.value=false;
      disLost.value=true;
      disPrposal.value=true;
    }
    else if (status=="Proposal") {
      disDead.value=false;
      dispReview.value=true;
      dispAccepted.value=true;
      dispRejected.value=true;
      dispDownload.value=true;
    }
    else if (status=="Negotiation Review") {
      disDead.value=false;
      disLost.value=true;
      //disPrposal.value=true;
      dispDownload.value=true;
      disrAccepted.value=true;
      disrRejected.value=true;
    }
    else if (status=="Closed Lost"||status=="Closed Won"){
    dispDownload.value=true;
    disDead.value=false;
    disLost.value=false;
    disPrposal.value=false;
    dispDownload.value=false;
    disrAccepted.value=false;
    disrRejected.value=false;
    disTarget.value=false;
    dispClosedWon.value=true;
    }
    };
    const localState = reactive({
      selectedRowKeys: 0,
      selectedRows: 0,
      visible: false,
      callVisible: false,
      historyVisible: false,
      noteVisible: false,
      fallVisible: false,
      schedVisible: false,
      proposalVisible: false,
      reviewVisible: false,
      qualyVisible: false,
      modalType: "primary",
      url: null,
    });
    const showModal = (id) => {
     localState.visible = true;
      let api_data = [];
      api_data['ids'] = id;
      dispatch("getCampaignIndex",api_data).then(
        (response) => {
          if (response.status == 200) {
            state.LeadsIndex.data = response.data;
           } else {
            state.LeadsIndex.data = [];
           }
        },
        (error) => {
          state.LeadsIndex.data = [];
        }
      );
    };
    const DateSelect = (date, dateString) => {
      selectrange.value = dateString;
      
    };
    const addCallDetails= (id,click_id) => {
    let api_data = [];
      api_data['ids'] = id;
      api_data['click_id'] = click_id;
      api_data['ScheduleType']=callTypes.value;
      api_data['name']=callFormState.name;
      api_data['contact_type']=callFormState.contact_type;
      api_data['calltype']=callFormState.calltype;
      api_data['date']=selectrange.value;
      api_data['hour']=callFormState.hour;
      api_data['min']=callFormState.min;
      api_data['assigned']=callFormState.assigned;
      api_data['note']=callFormState.note;
      api_data['current_status']=formState.status;
      
      dispatch("addScheduleIndex",api_data).then(
        (response) => {
          if (response.status == 200) {
          localState.fallVisible= false;
            Notification["success"]({
                    message: "Succsess!",
                    description: "Information Added.",
                    });
                    //location.reload();
                    //router.push('/');
           } else {
            //state.LeadsIndex.data = [];
           }
        },
        (error) => {
        localState.fallVisible= false;
          Notification["error"]({
                message: "Error!",
                description: "Error Adding Information.",
            });
            //location.reload();
        }
      );
    };
    const schedCallDetails= (id) => {
    let api_data = [];
      api_data['ids'] = id;
      api_data['ScheduleType']=schedFormState.type;
      api_data['name']=schedFormState.name;
      api_data['calltype']=schedFormState.calltype;
      api_data['date']=selectrange.value;
      api_data['hour']=schedFormState.hour;
      api_data['min']=schedFormState.min;
      api_data['assigned']=schedFormState.assigned;
      api_data['note']=schedFormState.note;
      api_data['current_status']=formState.status;
      
      dispatch("addScheduleIndex",api_data).then(
        (response) => {
          if (response.status == 200) {
          localState.fallVisible= false;
            Notification["success"]({
                    message: "Succsess!",
                    description: "Information Added.",
                    });
                    //location.reload();
                    router.push('/');
           } else {
            //state.LeadsIndex.data = [];
           }
        },
        (error) => {
        localState.fallVisible= false;
          Notification["error"]({
                message: "Error!",
                description: "Error Adding Information.",
            });
            location.reload();
        }
      );
    };
    const showNote = (id) => {
     localState.noteVisible = true;
     let api_data = [];
      api_data['ids'] = id;
      dispatch("getCommentIndex",api_data).then(
        (response) => {
          if (response.status == 200) {
            state.LeadsIndex.data = response.data;
           } else {
            state.LeadsIndex.data = [];
           }
        },
        (error) => {
          state.LeadsIndex.data = [];
        }
      );
     };
     const showModal3 = (type) => {
     localState.fallVisible = true;
     callTypes.value=type;
     };
     const showModal4 = () => {
     localState.schedVisible = true;
     };
     const showModal5 = () => {
     localState.proposalVisible = true;
     };
     const showModal6 = () => {
     localState.reviewVisible = true;
     };
     const showModal7 = () => {
     localState.qualyVisible = true;
     };
     const showModal8 = () => {
     localState.deadVisible = true;
     };
    const showCall = (id) => {
     localState.callVisible = true;
      let api_data = [];
      api_data['ids'] = id;
      dispatch("getCallIndex",api_data).then(
        (response) => {
          if (response.status == 200) {
            state.LeadsIndex.data = response.data;
           } else {
            state.LeadsIndex.data = [];
           }
        },
        (error) => {
          state.LeadsIndex.data = [];
        }
      );
    };
    const showHistory = (id) => {
     localState.historyVisible = true;
      let api_data = [];
      api_data['ids'] = id;
      dispatch("getHistoryIndex",api_data).then(
        (response) => {
          if (response.status == 200) {
            state.LeadsIndex.data = response.data;
           } else {
            state.LeadsIndex.data = [];
           }
        },
        (error) => {
          state.LeadsIndex.data = [];
        }
      );
    };
    const addInformation = (id) => {
     let api_data = [];
     api_data['ids'] = id;
     api_data['type'] = "Information";
     api_data['assignedto'] = infoFormState.assignedto;
     api_data['info'] = infoFormState.info;
     api_data['current_status'] = formState.status;
     dispatch("addInfoIndex",api_data).then(
        (response) => {
          if (response.status == 200) {
          localState.noteVisible= false;
            Notification["success"]({
                    message: "Succsess!",
                    description: "Information Added.",
                    });
                    //location.reload();
                    router.push('/');
           } else {
            state.LeadsIndex.data = [];
           }
        },
        (error) => {
        localState.noteVisible= false;
          Notification["error"]({
                message: "Error!",
                description: "Error Adding Information.",
            });
            location.reload();
        }
      );
    };
    const addComment = (id) => {
     let api_data = [];
     api_data['ids'] = id;
     api_data['type'] = "Comment";
     api_data['info'] = commentFormState.comment;
     dispatch("addComIndex",api_data).then(
        (response) => {
          if (response.status == 200) {
          localState.noteVisible= false;
            Notification["success"]({
                    message: "Succsess!",
                    description: "Information Added.",
                    });
                    //location.reload();
                    router.push('/');
           } else {
            state.LeadsIndex.data = [];
           }
        },
        (error) => {
        localState.noteVisible= false;
          Notification["error"]({
                message: "Error!",
                description: "Error Adding Information.",
            });
            location.reload();
        }
      );
    };
    const handleCancel = () => {
      onCancel();
    };
  const getdata = (id) => {
      loading.value = true;
      ids.value = id;
      let api_data = [];
      api_data['ids'] = id;
      dispatch("getLeadsIndexData",api_data).then(
        (response) => {
          if (response.status == 200) {
            state.LeadsIndex.data = response.data;
            leadsIndexLoad(response.data);
          } else {
            state.LeadsIndex.data = [];
           }
        },
        (error) => {
          state.LeadsIndex.data = [];
        }
      );
    };
    const loadData = () => {
        loading.value = true;
        dispatch("manualFieldsData").then(
            (response) => {
                if (response.status == 200) {
                    industryData.value = response.data[0];
                    countryData.value = response.data[1];
                    interestData.value = response.data[2];
                    branchData.value = response.data[3];
                    sourceData.value = response.data[4];
                    userData.value = response.data[5];
                    callType.value = response.data[6];
                    managerData.value=response.data[7];
                    loading.value = false;
                } else {
                    loading.value = false;
                  }
            },
            (error) => {
                loading.value = false;
                state.SearchData.data = [];
            } 
          
        );
    }
    const leadUpdate = (id) => {
        let api_data = [];
        api_data['id'] = id;
        api_data['company'] = formState.company;
        api_data['industry'] = formState.industry;
        api_data['website'] = formState.website;
        api_data['turnover'] = formState.turnover;
        api_data['noemployees'] = formState.noemployees;
        api_data['address1'] = formState.address1;
        api_data['address2'] = formState.address2;
        api_data['city'] = formState.city;
        api_data['country'] = formState.country;
        api_data['postal'] = formState.postal;
        api_data['name'] = formState.name;
        api_data['jobtitle'] = formState.jobtitle;
        api_data['dept'] = formState.dept;
        api_data['interested'] = formState.interested;
        api_data['board'] = formState.board;
        api_data['mobile'] = formState.mobile;
        api_data['phone'] = formState.phone;
        api_data['email'] = formState.email;
        api_data['linkedin'] = formState.linkedin;
        api_data['center'] = formState.center;
        api_data['opportunity'] = formState.opportunity;
        api_data['leadsource'] = formState.leadsource;
        api_data['leadtype'] = formState.leadtype;
        api_data['probability'] = formState.probability;
        api_data['email_unsubscribe'] = formState.isCheck;
        api_data['management_level'] = formState.management_level;
        api_data['founded_year'] = formState.founded_year;
        api_data['facebook'] = formState.facebook;
        api_data['twitter'] = formState.twitter;
        api_data['sla'] = selectrange1.value;
        api_data['live'] = selectrange2.value;
        loading.value = true;
        dispatch("leadUpdateData",api_data).then(
            (response) => {
                if (response.status == 200) {
                
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Lead Successfully Updated.",
                    });
                    //location.reload();
                    loading.value = false;
                } else {
                    loading.value = false;
                    
                }
            },
            (error) => {
              
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Updating Lead",
            });
            //location.reload();
          } 
          
        );
    }
    const leadDuplicate = (id) => {
        let api_data = [];
        api_data['id'] = id;
        api_data['company'] = formState.company;
        api_data['industry'] = formState.industry;
        api_data['website'] = formState.website;
        api_data['turnover'] = formState.turnover;
        api_data['noemployees'] = formState.noemployees;
        api_data['address1'] = formState.address1;
        api_data['address2'] = formState.address2;
        api_data['city'] = formState.city;
        api_data['country'] = formState.country;
        api_data['postal'] = formState.postal;
        api_data['name'] = formState.name;
        api_data['jobtitle'] = formState.jobtitle;
        api_data['dept'] = formState.dept;
        api_data['interested'] = formState.interested;
        api_data['board'] = formState.board;
        api_data['mobile'] = formState.mobile;
        api_data['email'] = formState.email;
        api_data['linkedin'] = formState.linkedin;
        api_data['center'] = formState.center;
        api_data['opportunity'] = formState.opportunity;
        api_data['leadsource'] = formState.leadsource;
        api_data['probability'] = formState.probability;
        api_data['email_unsubscribe'] = formState.isCheck;
        api_data['management_level'] = formState.management_level;
        api_data['founded_year'] = formState.founded_year;
        api_data['facebook'] = formState.facebook;
        api_data['twitter'] = formState.twitter;
        loading.value = true;
        dispatch("leadDuplicateData",api_data).then(
            (response) => {
                if (response.status == 200) {
                
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Lead Successfully Updated.",
                    });
                    //location.reload();
                    loading.value = false;
                } else {
                    loading.value = false;
                    
                }
            },
            (error) => {
              
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Updating Lead",
            });
            //location.reload();
          } 
          
        );
    }        
    const leadQualification = (id) => {
      let api_data = [];
      api_data['ids'] = id;
      api_data['current_status'] = formState.status;
      api_data['manager'] = qualyFormState.assign;
        dispatch("leadQualification",api_data).then(
            (response) => {
                if (response.status == 200) {
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Lead Qualified.",
                    });
                    router.push('/');
                   //location.reload();
                } else {
                    loading.value = false;
                   
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Qualifying Lead",
            });
            location.reload();
          }
          );
    }
     const acceptedProposal = (id) => {
      let api_data = [];
      api_data['ids'] = id;
      api_data['current_status'] = formState.status;
        dispatch("leadAcceptedProposal",api_data).then(
            (response) => {
                if (response.status == 200) {
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Lead Proposal Accepted.",
                    });
                    router.push('/');
                    //location.reload();
                } else {
                    loading.value = false;
                   
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Updating Lead",
            });
            location.reload();
          }
          );
    }
    const rejectedProposal = (id) => {
      let api_data = [];
      api_data['ids'] = id;
      api_data['current_status'] = formState.status;
        dispatch("leadRejectedProposal",api_data).then(
            (response) => {
                if (response.status == 200) {
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Lead Proposal Rejected.",
                    });
                     router.push('/');
                    //location.reload();
                } else {
                    loading.value = false;
                   
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Updating Lead",
            });
            location.reload();
          }
          );
    }
    const rejectedReview = (id) => {
      let api_data = [];
      api_data['ids'] = id;
      api_data['current_status'] = formState.status;
        dispatch("leadRejectedReview",api_data).then(
            (response) => {
                if (response.status == 200) {
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Lead Review Rejected.",
                    });
                    router.push('/');
                    //location.reload();
                } else {
                    loading.value = false;
                   
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Updating Lead",
            });
            location.reload();
          }
          );
    }
    const reviewProposal = (id) => {
      let api_data = [];
      api_data['ids'] = id;
      api_data['current_status'] = formState.status;
        dispatch("leadProposalReview",api_data).then(
            (response) => {
                if (response.status == 200) {
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Lead Proposal For Review.",
                    });
                    router.push('/nxtsprint/9');
                    //location.reload();
                } else {
                    loading.value = false;
                   
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Updating Lead",
            });
            location.reload();
          }
          );
    }
    const reviewAccepted = (id) => {
      let api_data = [];
      api_data['ids'] = id;
      api_data['selectedFile'] = file1.value.files[0];
      api_data['current_status'] = formState.status;
        dispatch("leadReviewAccepted",api_data).then(
            (response) => {
                if (response.status == 200) {
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Lead Review Accepted.",
                    });
                    router.push('/nxtsprint/8');
                    //location.reload();
                } else {
                    loading.value = false;
                   
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Updating Lead",
            });
            //location.reload();
          }
          );
    }
    const leadToTarget = (id) => {
      let api_data = [];
      api_data['ids'] = id;
      api_data['current_status'] = formState.status;
        dispatch("leadToTarget",api_data).then(
            (response) => {
                if (response.status == 200) {
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Lead Target Updated.",
                    });
                    router.push('/');
                    //location.reload();
                    loading.value = false;
                } else {
                    loading.value = false;
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Updating Lead",
            });
            location.reload();
          }       
        );
    }
    const leadProposal = (id) => {
      let api_data = [];
      api_data['ids'] = id;
      api_data['current_status'] = formState.status;
      api_data['selectedFile'] = file.value.files[0];
        dispatch("leadProposal",api_data).then(
            (response) => {
                if (response.status == 200) {
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Lead Target Updated.",
                    });
                    router.push('/nxtsprint/8');
                    //location.reload();
                    loading.value = false;
                } else {
                    loading.value = false;
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Updating Lead",
            });
            location.reload();
          }       
        );
    }
    const leadClosedLost = (id) => {
      let api_data = [];
      api_data['ids'] = id;
      api_data['current_status'] = formState.status;
        dispatch("leadClosedLost",api_data).then(
            (response) => {
                if (response.status == 200) {
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Lead Target Updated.",
                    });
                    router.push('/');
                    //location.reload();
                    loading.value = false;
                } else {
                    loading.value = false;
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Updating Lead",
            });
          location.reload();
          }       
        );
    }
    const leadDead = (id,click_id) => {
      let api_data = [];
      api_data['ids'] = id;
      api_data['click_id'] = click_id;
      api_data['comment'] = deadFormState.comment;
      api_data['notes'] = deadFormState.notes;
      api_data['current_status'] = formState.status;
        dispatch("leadDead",api_data).then(
            (response) => {
                if (response.status == 200) {
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Lead Dead.",
                    });
                    router.push('/');
                    //location.reload();
                    loading.value = false;
                } else {
                    loading.value = false;
                   
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Updating Lead Status",
            });
            location.reload();
          } 
          
        );
    }
    const CampaignColumns = [
    {
        title: "",
        dataIndex: "",
        key: "branch_code",
      },
    {
        title: "Id ",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.id.length - b.id.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Campaign Name",
        dataIndex: "campaign_name",
        key: "campaign_name",
        sorter: (a, b) => a.campaign_name.length - b.campaign_name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Campaign Date",
        dataIndex: "campaign_date",
        key: "campaign_date",
        sorter: (a, b) => a.campaign_date.length - b.campaign_date.length,
        sortDirections: ["descend", "ascend"],
      },
      
      
    ];
   
     let CampaignData = computed(() =>
        state.LeadsIndex.data.map((unreconcile) => {
          const {
            id,
            campaign_name,
            campaign_date,
            } = unreconcile;
          return {
            key: id,
            id: id,
            campaign_name: campaign_name,
            campaign_date:campaign_date,
            } 
            
        }
        )
     
    );
    const CallColumns = [
    {
        title: "Id ",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.id.length - b.id.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Contact Name",
        dataIndex: "contact_name",
        key: "contact_name",
        sorter: (a, b) => a.contact_name.length - b.contact_name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Call Conversion",
        dataIndex: "conversion_details",
        key: "conversion_details",
        sorter: (a, b) => a.conversion_details.length - b.conversion_details.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Call Duration",
        dataIndex: "elapsed_time",
        key: "elapsed_time",
        sorter: (a, b) => a.elapsed_time.length - b.elapsed_time.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Call Type",
        dataIndex: "Call_type",
        key: "Call_type",
        sorter: (a, b) => a.Call_type.length - b.Call_type.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Contact Type",
        dataIndex: "contact_type",
        key: "contact_type",
        sorter: (a, b) => a.contact_type.length - b.contact_type.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Date",
        dataIndex: "t_datetime",
        key: "t_datetime",
        sorter: (a, b) => a.t_datetime.length - b.t_datetime.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "User",
        dataIndex: "user_name",
        key: "user_name",
        sorter: (a, b) => a.user_name.length - b.user_name.length,
        sortDirections: ["descend", "ascend"],
      },
    ];
    let CallData = computed(() =>
        state.LeadsIndex.data.map((unreconcile) => {
          const {
            id,
            contact_name,
            conversion_details,
            elapsed_time,
            Call_type,
            contact_type,
            t_datetime,
            user_name,
            } = unreconcile;
          return {
            key: id,
            id: id,
            contact_name: contact_name,
            conversion_details:conversion_details,
            elapsed_time: elapsed_time,
            Call_type:Call_type,
            contact_type: contact_type,
            t_datetime: t_datetime,
            user_name:user_name,
            } 
          }
        )
     
    );
const HistoryColumns = [
    {
        title: "Transaction Date",
        dataIndex: "datetime",
        key: "datetime",
        sorter: (a, b) => a.datetime.length - b.datetime.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Action Taken By",
        dataIndex: "user",
        key: "user",
        sorter: (a, b) => a.user.length - b.user.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Previous Status",
        dataIndex: "pre_status",
        key: "pre_status",
        sorter: (a, b) => a.pre_status.length - b.pre_status.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        sorter: (a, b) => a.action.length - b.action.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Current Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => a.status.length - b.status.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Assigned To",
        dataIndex: "Assign_To",
        key: "Assign_To",
        sorter: (a, b) => a.Assign_To.length - b.Assign_To.length,
        sortDirections: ["descend", "ascend"],
      },
    ];
    let HistoryData = computed(() =>
        state.LeadsIndex.data.map((unreconcile) => {
          const {
            id,
            datetime,
            user,
            status,
            Assign_To,
            pre_status,
            action,
            } = unreconcile;
          return {
            key: id,
            id: id,
            datetime: datetime,
            user:user,
            status: status,
            Assign_To:Assign_To,
            pre_status: pre_status,
            action:action,
            } 
          }
        )
     
    );
const CommentColumns = [
    {
        title: "DateTime",
        dataIndex: "Created_at",
        key: "Created_at",
        sorter: (a, b) => new Date(a.Created_at) - new Date(b.Created_at),
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Comment/Info",
        dataIndex: "Text",
        key: "Text",
        sorter: (a, b) => a.Text.length - b.Text.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Type",
        dataIndex: "Type",
        key: "Type",
        sorter: (a, b) => a.Type.length - b.Type.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Created By",
        dataIndex: "author",
        key: "author",
        sorter: (a, b) => a.author.length - b.author.length,
        sortDirections: ["descend", "ascend"],
      },
      
    ];
let CommentData = computed(() =>
        state.LeadsIndex.data.map((unreconcile) => {
          const {
            id,
            Text,
            author,
            Type,
            assignedto,
            Created_at,
            lead_id,
            } = unreconcile;
          return {
            key: id,
            id: id,
            Text: Text,
            assignedto:assignedto,
            Created_at: Created_at,
            author:author,
            Type: Type,
            lead_id:lead_id,
            } 
          }
        )
     
    );
    return {
      loading,
      CampaignColumns,
      CampaignData,
      CallColumns,
      CallData,
      showCall,
      showModal3,
      showModal4,
      showModal5,
      showModal6,
      file,
      file1,
      HistoryColumns,
      HistoryData,
      CommentColumns,
      CommentData,
      showHistory,
      leadUpdate,
      selectcount,
      checkselected,
      selectrange,
      DateSelect,
      selectedoption,
      handleOk,
      handleOk1,
      handleOk3,
      handleOk4,
      handleOk5,
      handleOk6,
      addCallDetails,
      schedCallDetails,
      formState,
      infoFormState,
      commentFormState,
      callFormState,
      schedFormState,
      proposalFormState,
      reviewFormState,
      getdata,
      checked,
      onChange,
      industryData,
      countryData,
      showModal,
      interestData,
      branchData,
      sourceData,
      userData,
      callType,
      onCancel,
      supplierdrop,
      leadsIndexLoad,
      showNote,
      path,
      reviewProposal,
      reviewAccepted,
      acceptedProposal,
      rejectedProposal,
      rejectedReview,
      leadQualification,
      leadDead,
      rules,
      rules1,
      rules2,
      leadProposal,
      leadClosedLost,
      leadToTarget,
      localState,
      handleCancel,
      orgdata,
      btnDisplay,
      disDead,
      disTarget,
      disQualify,
      disLost,
      disPrposal,
      dispAccepted,
      dispReview,
      dispRejected,
      disrAccepted,
      disrRejected,
      dispDownload,
      dispClosedWon,
      disVal,
      disAdmin,
      route,
      router,
      isChecked,
      selectrange1,
      selectrange2,
      DateSelect1,
      DateSelect2,
      leadDuplicate,
      qualyFormState,
      showModal7,
      handleOk7,
      deadFormState,
      showModal8,
      handleOk8,
      managerData,
    };
  },
});
</script> 
<style scoped>
.ant-table-striped :deep(.table-striped) td th{
  background-color: #447316;
}
th {
  //background-color: #93BF34 !important;
}
.ant-btn-primary {
  width: 180px !important
}
.supp-head div{
        width: 250px;
}

.ant-col .ant-select {
  margin-top: 4px;
}
.ant-col button{
  margin-top: 5px;
}
table thead tr th:last-child {
    text-align: left !important;
}
</style>