<template>

   <sdPageHeader>
      <template #title>
      <a-row :gutter="30">
        <a-col>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 15px">
           Add New Leads
          </sdHeading>
          <span style="margin: 0px; font-size: 16px; font-weight: 400">
          </span>
        </div>
        </a-col>
        </a-row>
        <ProductCard
    class="list-view"
    :style="{ width: '100%', marginBottom: '10px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;">
            <a-form
              name="contact"
              :rules="rules"
              :model="formState"
              @submit="handleOk"
              layout="horizontal"
            >
            <a-row :gutter="30" style="" >
            <sdHeading class="product-single-title" as="h3" style="width: 100% !important; border-bottom: 1px solid #447316 !important;color: #447316 !important; margin: 15px" >
           Company Details:
          </sdHeading>
            </a-row>
            <a-row :gutter="30">
            <a-col :md="5">
              <a-form-item name="company" label="Company Name:">
                <a-input
                  v-model:value="formState.company"
                  placeholder="Input Company Name"
                />
              </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="industry" label="Industry Type:">
            <a-select
                v-model:value="formState.industry"
                style="width: 100%"
                showSearch
            >
                <a-select-option
                  v-for="item in industryData"
                  v-bind:key="item.vertical_type"
                  :value="item.vertical_type"
                >
                  {{ item.vertical_type }}
                  </a-select-option>
            </a-select>    
            </a-form-item>
            </a-col>
            <a-col :md="4">
              <a-form-item name="website" label="Website Address:">
                <a-input
                  v-model:value="formState.website"
                  placeholder="Input Website"
                />
              </a-form-item>
            </a-col>
            <a-col :md="4">
              <a-form-item name="turnover" label="Annual Turnover:">
                <a-input
                  v-model:value="formState.turnover"
                  placeholder="Input Annual Turnover"
                />
              </a-form-item>
            </a-col>
            <a-col :md="4">
              <a-form-item name="noemployees" label="No of Employees:">
                <a-input
                  v-model:value="formState.noemployees"
                  placeholder="Input No of Employees"
                />
              </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="30">
            <a-col :md="5">
              <a-form-item name="address1" label="Address 1:">
                <a-textarea
                  v-model:value="formState.address1"
                  placeholder="Input Address"
                />
              </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="address2" label="Address 1:">
            <a-textarea
                  v-model:value="formState.address2"
                  placeholder="Input Address"
                />   
            </a-form-item>
            </a-col>
            <a-col :md="4">
              <a-form-item name="city" label="City:">
                <a-input
                  v-model:value="formState.city"
                  placeholder="Input City"
                />
              </a-form-item>
            </a-col>
            <a-col :md="4">
              <a-form-item name="country" label="Country:">
                <a-select
                v-model:value="formState.country"
                style="width: 100%"
                showSearch
                >
                <a-select-option
                  v-for="item in countryData"
                  v-bind:key="item.country_name"
                  :value="item.country_name"
                >
                  {{ item.country_name }}
                  </a-select-option>
                </a-select>  
              </a-form-item>
            </a-col>
            <a-col :md="4">
              <a-form-item name="postal" label="Postal Code:">
                <a-input
                  v-model:value="formState.postal"
                  placeholder="Input Postal Code"
                />
              </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="30" style="" >
            <sdHeading class="product-single-title" as="h3" style="width: 100% !important; border-bottom: 1px solid #447316 !important;color: #447316 !important; margin: 15px" >
           Contact Person Details:
          </sdHeading>
            </a-row>
            <a-row :gutter="30">
            <a-col :md="5">
              <a-form-item name="name" label="Name:">
                <a-input
                  v-model:value="formState.name"
                  placeholder="Input Contact Person"
                />
              </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="jobtitle" label="Job Title:">
               <a-input
                  v-model:value="formState.jobtitle"
                  placeholder="Input Job Title"
                />
            </a-form-item>
            </a-col>
            <a-col :md="4">
              <a-form-item name="dept" label="Deptarment Name:">
                <a-input
                  v-model:value="formState.dept"
                  placeholder="Input Deptarment Name"
                />
              </a-form-item>
            </a-col>
            <a-col :md="4">
              <a-form-item name="interested" label="Interested In:">
                <a-select
                v-model:value="formState.interested"
                style="width: 100%"
                mode="multiple"
                maxTagCount=4
                showSearch
                >
                <a-select-option
                  v-for="item in interestData"
                  v-bind:key="item.intrest_name"
                  :value="item.intrest_name"
                >
                  {{ item.intrest_name }}
                  </a-select-option>
            </a-select> 
              </a-form-item>
            </a-col>
            <a-col :md="5">
              <a-form-item name="board" label="Board Nos:">
                <a-textarea
                  v-model:value="formState.board"
                  placeholder="Input Board Nos"
                />
              </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="30">
            <a-col :md="5">
              <a-form-item name="mobile" label="Mobile:">
                <a-input
                  v-model:value="formState.mobile"
                  placeholder="Input Mobile"
                />
              </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="email" label="E-mail Address:">
            <a-input
                  v-model:value="formState.email"
                  placeholder="Input E-mail Address"
                />   
            </a-form-item>
            </a-col>
            <a-col :md="5">
              <a-form-item name="center" label="Center:">
                <a-select
                v-model:value="formState.center"
                style="width: 100%"
                showSearch
                >
                <a-select-option
                  v-for="item in branchData"
                  v-bind:key="item.branch_code"
                  :value="item.branch_code"
                >
                  {{ item.branch_code }}
                  </a-select-option>
            </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="linkedin" label="Linkedin:">
            <a-input
                  v-model:value="formState.linkedin"
                  placeholder="Input LinkedIn"
            />   
            </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="30" style="" >
            <sdHeading class="product-single-title" as="h3" style="width: 100% !important; border-bottom: 1px solid #447316 !important;color: #447316 !important; margin: 15px" >
           Target/Leads Details:
          </sdHeading>
            </a-row>
            <a-row :gutter="30">
            <a-col :md="5">
              <a-form-item name="opportunity" label="Opportunity Amount:">
                <a-input
                  v-model:value="formState.opportunity"
                  placeholder="Input Apportunity Amount"
                />
              </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="leadsource" label="Data Source:">
            <a-select
                v-model:value="formState.leadsource"
                style="width: 100%"
                showSearch
                >
               <a-select-option
                  v-for="item in sourceData"
                  v-bind:key="item.Source_type"
                  :value="item.Source_type"
                >
                  {{ item.Source_type }}
                  </a-select-option>
            </a-select>    
            </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="leadtype" label="Lead Type:">
            <a-select
                v-model:value="formState.leadtype"
                style="width: 100%"
                showSearch
                >
               <a-select-option value="Email Campaign">
               Email Campaign
               </a-select-option>
               <a-select-option value="Cold Call">
               Cold Call
               </a-select-option>
               <a-select-option value="Inbound Call">
               Inbound Call
               </a-select-option>
               <a-select-option value="Inquiry Form">
               Inquiry Form
               </a-select-option>
               <a-select-option value="Referal">
               Referal
               </a-select-option>
               <a-select-option value="Cold Email">
               Cold Email
               </a-select-option>
               <a-select-option value="Facebook Ads">
               Facebook Ads
               </a-select-option>
               <a-select-option value="Google Ads">
               Google Ads
               </a-select-option>
               <a-select-option value="">
               Type Not Selected
               </a-select-option>
            </a-select>    
            </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="assignedto" label="Assigned to:">
            <a-select
                v-model:value="formState.assignedto"
                style="width: 100%"
                showSearch
                >
                <a-select-option
                  v-for="item in userData"
                  v-bind:key="item.user_id"
                  :value="item.user_id"
                >
                  {{ item.user_id }}
                  </a-select-option>
            </a-select>    
            </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="30">
            <a-col :md="3">
            <a-form-item>
              <a-button 
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
              >
               <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="plus-circle" size="14" />  &nbsp; 
              Add Target</span>
              </a-button>
             </a-form-item>
              </a-col>
             <a-col :md="5" v-if="adminoppo">
             <a-form-item>
              <a-button
                size="default"
                type="primary"
                @click="opportunityData()"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="check-circle" size="14" /> &nbsp;
              Add Prospect</span>
              </a-button>
              </a-form-item>
              </a-col>
              </a-row>
            </a-form>
        </div>
          </ProductCard>
      </template>
</sdPageHeader>
</template> 
<script>
import { ContactPageheaderStyle, Action, UserTableStyleWrapper ,ProjectSorting,TopToolBox,} from '../../style';
import { defineComponent } from 'vue';
import { Main, CardToolbox, TableWrapper,BasicFormWrapper } from '../../../styled';
import { dispatch, useStore } from 'vuex';
import { ProductCard } from "./style";
import { defineAsyncComponent, computed, reactive, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { message, Notification } from 'ant-design-vue'; 
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default defineComponent({
  name: 'SearchForm',
  components: {
    Main,
    CardToolbox,
    BasicFormWrapper,
    UserTableStyleWrapper,
    TableWrapper,ProjectSorting,TopToolBox,ProductCard,
  },
  setup() {
    var loading = ref(true);
    var industryData = ref();
    var countryData = ref();
    var interestData = ref();
    var branchData = ref();
    var sourceData = ref();
    var userData = ref();
    var checkselected = ref(false);
    var adminoppo = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref('Active');
    const { dispatch, state } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT; 
    state.NewLeads.data = [];
    state.SearchData.data = [];
    onMounted(() => {
    loadData();
    disVal();
    }); 
    const formState = reactive({
    company:"",
    industry:"",
    website:"",
    turnover:"",
    noemployees:"",
    address1:"",
    address2:"",
    city:"",
    country:"",
    postal:"",
    name:"",
    jobtitle:"",
    dept:"",
    board:"",
    mobile:"",
    email:"",
    center:"",
    linkedin:"",
    opportunity:"",
    leadsource:"",
    leadtype:"",
    assignedto:"",   
    });
    const rules = {
      company: [
        {
          required: true,
          message: "Please select Company",
          trigger: "blur",
        },
      ],
      industry: [
        {
          required: true,
          message: "Please select Industry",
          trigger: "blur",
        },
      ],
      name: [
        {
          required: true,
          message: "Please select Name",
          trigger: "blur",
        },
      ],
      jobtitle: [
        {
          required: true,
          message: "Please Add Job title",
          trigger: "blur",
        },
      ],
      board: [
        {
          required: true,
          message: "Please Add Board Numbers",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "Please Add Email Address",
          trigger: "blur",
        },
      ],
      center: [
        {
          required: true,
          message: "Please Add Centers",
          trigger: "blur",
        },
      ],
      leadsource: [
        {
          required: true,
          message: "Please Add Data Source",
          trigger: "blur",
        },
      ],
      assignedto: [
        {
          required: true,
          message: "Please Add Assignment",
          trigger: "blur",
        },
      ],
      };
    const handleOk = (values) => {
      targetData();
    };
    const disVal = () => {
   
     let role = JSON.parse(localStorage.getItem("usr_role"));
      
      //alert(role.role_id);
      if (role.role_id=="1" ) {
            adminoppo.value=true;
          } 
      else{
            adminoppo.value=false;
          }
    };
    
    const loadData = () => {
        loading.value = true;
        dispatch("manualFieldsData").then(
            (response) => {
                if (response.status == 200) {
                    //state.SearchData.data = response.data;
                    industryData.value = response.data[0];
                    countryData.value = response.data[1];
                    interestData.value = response.data[2];
                    branchData.value = response.data[3];
                    sourceData.value = response.data[4];
                    userData.value = response.data[5];
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                state.SearchData.data = [];
            } 
          
        );
    }
    
    const targetData = () => {
        let api_data = [];
        api_data['company'] = formState.company;
        api_data['industry'] = formState.industry;
        api_data['website'] = formState.website;
        api_data['turnover'] = formState.turnover;
        api_data['noemployees'] = formState.noemployees;
        api_data['address1'] = formState.address1;
        api_data['address2'] = formState.address2;
        api_data['city'] = formState.city;
        api_data['country'] = formState.country;
        api_data['postal'] = formState.postal;
        api_data['name'] = formState.name;
        api_data['jobtitle'] = formState.jobtitle;
        api_data['dept'] = formState.dept;
        api_data['interested'] = formState.interested;
        api_data['board'] = formState.board;
        api_data['mobile'] = formState.mobile;
        api_data['email'] = formState.email;
        api_data['center'] = formState.center;
        api_data['linkedin'] = formState.linkedin;
        api_data['opportunity'] = formState.opportunity;
        api_data['leadsource'] = formState.leadsource;
        api_data['leadtype'] = formState.leadtype;
        api_data['assignedto'] = formState.assignedto;
        loading.value = true;
        dispatch("addtargetdata",api_data).then(
            (response) => {
                if (response.status == 200) {
                
                    Notification["success"]({
                    message: "Succsess!",
                    description: "New Target Added.",
                    });
                    //location.reload();
                    loading.value = false;
                } else {
                    loading.value = false;
                    
                }
            },
            (error) => {
                var msg= error.data.msg;
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: msg,
            });
            //location.reload();
          } 
          
        );
    }
const opportunityData = () => {
        let api_data = [];
        api_data['company'] = formState.company;
        api_data['industry'] = formState.industry;
        api_data['website'] = formState.website;
        api_data['turnover'] = formState.turnover;
        api_data['noemployees'] = formState.noemployees;
        api_data['address1'] = formState.address1;
        api_data['address2'] = formState.address2;
        api_data['city'] = formState.city;
        api_data['country'] = formState.country;
        api_data['postal'] = formState.postal;
        api_data['name'] = formState.name;
        api_data['jobtitle'] = formState.jobtitle;
        api_data['dept'] = formState.dept;
        api_data['interested'] = formState.interested;
        api_data['board'] = formState.board;
        api_data['mobile'] = formState.mobile;
        api_data['email'] = formState.email;
        api_data['center'] = formState.center;
        api_data['linkedin'] = formState.linkedin;
        api_data['opportunity'] = formState.opportunity;
        api_data['leadsource'] = formState.leadsource;
        api_data['assignedto'] = formState.assignedto;
        loading.value = true;
        dispatch("addopportunitydata",api_data).then(
            (response) => {
                if (response.status == 200) {
                
                    Notification["success"]({
                    message: "Succsess!",
                    description: "New Opportunity Added.",
                    });
                    //location.reload();
                    loading.value = false;
                } else {
                    loading.value = false;
                    
                }
            },
            (error) => {
              
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Adding Lead",
            });
            //location.reload();
          } 
          
        );
    }    
    
    return {
      loading,
      industryData,
      countryData,
      interestData,
      branchData,
      sourceData,
      userData,
      selectcount,
      checkselected,
      selectedoption,
      handleOk,
      formState,
      rules,
      opportunityData,
      adminoppo,
      disVal,
      
     };
  },
});
</script> 
<style scoped>
.ant-table-striped :deep(.table-striped) td th{
  background-color: #447316;
}
th {
  //background-color: #93BF34 !important;
}
.ant-btn-primary {
  background-color: #5C69B3 !important;
  border: 1px solid #5C69B3 !important;
}
.supp-head div{
        width: 250px;
}

.ant-col .ant-select {
  margin-top: 4px;
}
.ant-col button{
  margin-top: 5px;
}
table thead tr th:last-child {
    text-align: left !important;
}
.ant-page-header-heading {
  display: block !important;

}
</style>